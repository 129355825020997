import React, {useState, useEffect} from 'react'
import {firestore} from '../firebase'
import {Select} from 'antd'

const TeamSelect = (props) => {
    
    const [teamSelectOptions, setTeamSelectOptions] = useState([]);
    //const {placeholder, selectChanged} = props;

    useEffect( () => {
        async function fetchItems() {
            const teamRefs = await firestore.collection('teams').orderBy('name').get();
            
            setTeamSelectOptions(teamRefs.docs.map(team => {
                                    return (<Select.Option key={team.id} value={team.id}>{team.data().name}</Select.Option>)
                                        }
                                ));
        }

        fetchItems();

       
    }, [])
    
    return (
        <Select {...props}>
            {teamSelectOptions}
        </Select>
    )
}

export default TeamSelect;

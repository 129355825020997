import React, {useEffect, useState, useRef} from 'react'
import {Breadcrumb, Layout, PageHeader, Button, message, Popconfirm, Table, Input, Row, Col, Space, List } from 'antd'
import { PlusOutlined, LeftOutlined, RightOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import { firestore } from '../firebase'
import {debounce} from '../shared/utilities'
import { useRecoilState } from 'recoil'
import { userState } from '../atoms'






function ListGroups() {
  
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  const resultsPerPage = 20;
    
  //state
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ searchText, setSearchText] = useState('');
  const [ tableParams, setTableParams] = useState({});
  const [ lastDocRef, setLastDocRef] = useState(null);
  const [ firstDocRef, setFirstDocRef] = useState(null);
  const [ prevDisabled, setPrevDisabled] = useState(true);
  const [ nextDisabled, setNextDisabled] = useState(false);
  const [ currentPage, setCurrentPage] = useState(1);
  const [ totalItems, setTotalItems] = useState(null);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  

  let unsubscribeFromTotalItems = null;

    
  const onCellClick = (record) => {
    return {
      onClick: event => { history.push({
        pathname: `/groups/${record.id}`,
        state: record
      })}
    }
  }

  const fetch = async (params = {}, searchTextToUse = null, movePage = null) => {
      
    if (searchTextToUse) {
        searchTextToUse = searchTextToUse.toLowerCase();
      }
  
      
  
      //console.log("fetch params",params);
      
      setTableParams(params);
      let sortField = params.sortField ? params.sortField : "name";
      let sortOrder = 'asc';
      
      setLoading(true);
      
      try {
        let refs = null;
        let startAfter = '';
        //let limit = 10;
        if (movePage === "next") {
          startAfter = lastDocRef;
        }
  
        let query = firestore.collection("groups").orderBy(sortField, sortOrder).limit(resultsPerPage);

        if (currentUser.role !== 'admin') {
          query = query.where('team_id','==',currentUser.team_id)
        }

        if (searchTextToUse) {
          query = query.where('search_fields', 'array-contains', searchTextToUse);
        }
  
        
        if (movePage === "next" || !movePage) {
        //Moving to next page or getting first page
          if (startAfter) {
            query = query.startAfter(startAfter);
          }
        } else {
        //Moving to Previous Page
          query = query.endBefore(firstDocRef).limitToLast(resultsPerPage)
        }
        refs = await query.get();
        
        if (refs.docs[refs.docs.length -1] ) {
          setLastDocRef(refs.docs[refs.docs.length -1]);
        } 
        
        if (refs.docs[0]) {
          setFirstDocRef(refs.docs[0]);
        } else {
          //no results on this page, set First Doc to Last Doc of previous page for moving backwards at the end
          setFirstDocRef(lastDocRef);
        }
       
        
        let groupPromises = [];
        let results = [];
        refs.forEach((doc, index) => {
          results.push({id: doc.id, ...doc.data()});
          //query to get users for this group
          groupPromises.push(firestore.collection('users').where('group_id','==',doc.id).orderBy('first_name').get())
        });

        //get users for each group and add to results
        if (groupPromises.length > 0) {
          let promiseResults = await Promise.all(groupPromises);
          promiseResults.forEach((groupUsers,index) => {
            results[index].users = [];
            results[index].numUsers = groupUsers.docs.length;
            groupUsers.forEach(user => {
              results[index].users.push(`${user.data().first_name} ${user.data().last_name}`);
            })
          })
        }
        
        //console.log('results',results);
          
        setData(results);
  
        let page = currentPage;
        if (params.page) {
          page = params.page;
          console.log("does this ever run???");
        }
        
        if (results.length < resultsPerPage) {
          setNextDisabled(true);
        } else {
          setNextDisabled(false);
        }
  
        
        if (movePage === 'next') {
          page = currentPage+1;
        }
        //console.log("page",page,"resultsPerPage",resultsPerPage, "totalItems",totalItems);
        if (page*resultsPerPage === totalItems) {
          console.log("disabling next");
          setNextDisabled(true);
        }
  
        if (movePage === 'next') {
          setPrevDisabled(false);
          setCurrentPage(prevCurrentPage => prevCurrentPage + 1 );
        }
  
        if (movePage === 'previous') {
          if (currentPage === 2) {
            setPrevDisabled(true);
          }
          setNextDisabled(false);
          setCurrentPage(prevCurrentPage => prevCurrentPage - 1 );
        }
       
        setLoading(false);
  
      } catch(error) {
        console.log("Error getting documents: ", error);
      }
  };

  const { current: fetchItems } = useRef(fetch);

  const subscribeTototalItems = () => {
    unsubscribeFromTotalItems = firestore.doc("counters/groups").onSnapshot((doc) => {
      if (doc.data()) {
        console.log("Total Groups",doc.data().count);
        setTotalItems(doc.data().count);
      }
      
    })
    
  }

  const { current: sub } = useRef(subscribeTototalItems);
  //const { current: unsub } = useRef(unsubscribeFromtotalItems);
  
  useEffect(() => {
      sub();
     
      fetchItems();
      return () => {
        unsubscribeFromTotalItems();
      }

    
  },[sub,fetchItems])

  let columns = [];

  if (currentUser.role === 'admin') {
    columns = [{
      title: 'Team',
      dataIndex: 'team_name',
      //width: '30%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    }]
  }
  
  columns = [...columns,
    
    {
      title: 'Name',
      dataIndex: 'name',
      //width: '30%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
      title: '# of Users',
      dataIndex: 'numUsers',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primary_contact_name',
      //width: '40%',
      onCell: (record) => onCellClick(record),
      className: "item-cell"
    },
    {
    title: 'Secondary Contact',
    dataIndex: 'secondary_contact_name',
    //width: '40%',
    onCell: (record) => onCellClick(record),
    className: "item-cell"
  },
  
     
    
    {
      title: 'Action',
      
      
      render: (text, record) => {
        return (
          <Popconfirm
            title={"Delete Group: " + record.name + "?"}
            onConfirm={() => deleteEntry(record.id)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
        )
      }
    }
  ];

  const deleteEntry = (id) => {
    
    
    //Delete Database Entry
    firestore.collection("groups").doc(id).delete()
    .then(() => {
      console.log("Document successfully deleted!");
      message.success('Group Deleted');
     
      fetch();
    }).catch(function(error) {
        console.error("Error removing document: ", error);
        message.error('Error deleting group ');
    });
  }
    
    const cancel = (e) => {
      console.log(e);
      
    }

    const handleTableChange = (pag, filters, sorter) => {
      //const pager = { ...pagination };
      //pager.current = pag.current;
      setCurrentPage(1);
      setPrevDisabled(true);
      //setPagination({...pager},
        fetch({
          results: pag.pageSize,
          page: pag.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
          
        })
        
      //  );
      
      
      
    };


    const onTextSearch = debounce((value) => {
           console.log("onKeystroke",value);
           setCurrentPage(1);
           setPrevDisabled(true);
           setSearchText(value, setCurrentPage(1,fetch(tableParams,value)) );
           //fetch(tableParams,value);
    },250);


    const userRow = (record) => {
      return (
        <List bordered style={{margin: 20}}size="small" dataSource={record.users} renderItem={item => <List.Item>{item}</List.Item>} />
      )
    }
  


    return (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Groups</Breadcrumb.Item>
            
          </Breadcrumb>
       
        
          <Content className="site-layout-background">
          
       
            <PageHeader
            className="site-page-header-responsive"
            avatar={{icon: <UsergroupAddOutlined />}}
            ghost={false}
            onBack={() => window.history.back()}
            title="Groups"
            
            backIcon={false} 
            extra={[
               
              <Row gutter={[8,8]} key="extra_row">
                <Col lg={14} sm={24} key="search">
                    <Search
                    placeholder="search by name"
                    block="true"
                    //style={{ width: 200 }}
                    key="search"
                    onChange={(e) => onTextSearch(e.target.value)}
                  />
                </Col>
                <Col lg={10} sm={24} key="create_new">
                  <Button key="/" type="primary" block="true" onClick={() => {history.push("/groups/new")}}>
                  <PlusOutlined />Create New
                  </Button>
                </Col>
              </Row>
              
            ]}
            >
            
            </PageHeader>

            <Table
              columns={columns}
              className="ant-table"
              //scroll={{x: 'calc(100vh - 4em)'}}
              scroll={{ x: 'max-content' }}
              rowKey={record => record.id}
              sortDirections={['ascend']}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              size="small"
              expandable = {{expandedRowRender: userRow}}
              
            />
            <Row gutter={[8,8]} align="middle" justify="end" style={{marginTop: '16px'}} >
              <Col >
                <span>
                  { (searchText || currentUser.role !== 'admin') ? null : (currentPage-1)*resultsPerPage+1 + " to " + (totalItems < currentPage*resultsPerPage ? totalItems : currentPage*resultsPerPage ) + " of " + totalItems + " Total Entries"}
                </span>
              </Col>
              <Col >
                <Space>
                  <Button disabled={prevDisabled} key="previous" type="default" onClick={() => fetch(tableParams, searchText, "previous")}>
                      <LeftOutlined />Previous
                    </Button>
                  
                  <Button disabled={nextDisabled} key="next" type="default" onClick={() => fetch(tableParams, searchText, "next")}>
                      <RightOutlined />Next
                    </Button>
                </Space>
              </Col> 
                
                
              
            </Row>

        </Content>
            
        </div>
    )
}

export default ListGroups

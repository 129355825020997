import React, {useState, memo} from 'react'
import GoogleMapReact from 'google-map-react';
import {Radio} from 'antd';
import Marker from './Marker/Marker';
import {googleMapsKey} from '../firebase'

const GoogleMap = memo((props) => {
    const [mapType, setMapType] = useState('roadmap');
    const { users, center, setCenter, zoom, setZoom } = props;

    const defaultProps = {
        center: {
          lat: 49.5250615,
          lng: -96.6832357
        },
        zoom: 13
      };

    const handleMapType = e => {
        setMapType(e.target.value);
    }

    const handleMapChange = (value) => {
        //console.log('map changed', value);
        if (setCenter) {
            setCenter(value.center);
        }
        if (setZoom) {
            setZoom(value.zoom);
        }
        
    }

    //console.log("map Users",users);
    
    return (
        <>
            
            <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapsKey }}
                onChange={handleMapChange}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                center={center}
                zoom={zoom}
                
                options={map => ({ 
                                mapTypeId: mapType
                                })
                }
                >
                    {users.map(user => {
                       
                            return (<Marker key={user.id ? user.id : user.name } lat={user.lat} lng={user.lng} name={user.name} />)
                                            })}
            </GoogleMapReact>

            <div style={{position:'absolute', bottom: '20px', marginLeft: 80, width:'50%', textAlign:'right'}}>
                <Radio.Group
                style={{marginBottom:20, marginRight:20}}
                options={ [{ label: 'Map', value: 'roadmap' },
                            { label: 'Satellite', value: 'hybrid' },]}
                onChange={handleMapType}
                value={mapType}
                optionType="button"
                //buttonStyle="solid"
                />
            </div> 
        </>
    )
})

export default GoogleMap

import React, {useState, useEffect} from 'react';
import { Layout, Menu, message, Grid, notification, Tag} from 'antd';
import { UserOutlined, LogoutOutlined, SafetyCertificateOutlined, WarningTwoTone, SettingOutlined, FieldTimeOutlined, UsergroupAddOutlined, LockOutlined } from '@ant-design/icons';
import logo from './assets/loginLogo.png'
import mobileLogo from './assets/logoMobile.png'
import { Route, Switch, Redirect, useHistory} from 'react-router-dom'
import UserForm from './Users/UserForm/UserForm'
import ListUsers from './Users/ListUsers/ListUsers'
import GroupForm from './Groups/GroupForm'
import ListGroups from './Groups/ListGroups'
import TeamForm from './Teams/TeamForm'
import ListTeams from './Teams/ListTeams'
import Checkin from './Checkin/Checkin'
import Settings from './Settings/Settings'
import ActivityLog from './ActivityLog/ActivityLog'
import LiveDashboard from './LiveDashboard/LiveDashboard'
import Login from './Login/Login'
import './App.less';
import MenuItem from 'antd/lib/menu/MenuItem';
import { auth, firestore, checkNotificationSubscription, messaging, environment, apiKey } from './firebase';
import { userState, locationState, firestoreReadCount, nativeLocationEnabledState } from './atoms'
import {useRecoilState} from 'recoil';
import { RiMapPinUserLine } from "react-icons/ri";
import RunFirestoreUpdate from './Admin/RunFirestoreUpdate'
import Backdrop from './shared/Backdrop/Backdrop'

//import { BackgroundGeolocation, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationResponse } from '@ionic-native/background-geolocation';
import {setupBackgroundGeoLocation, startTracking, stopTracking, emailLog} from './BackgroundGeoLocation/BackgroundGeoLocation'
import {setupPushNotifications} from './PushNotifications/PushNotifications'
import {Capacitor, Plugins} from '@capacitor/core';

const { useBreakpoint } = Grid;
//const { PushNotifications } = Plugins;

let unsubscribeUser = null;





function App() {
  
  const screens = useBreakpoint();
  const { Header, Sider } = Layout;
  //const [currentUser,setCurrentUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const history = useHistory();
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const [currentLocation, setCurrentLocation] = useRecoilState(locationState);
  const [readCount, setReadCount] = useRecoilState(firestoreReadCount);
  const [nativeLocationEnabled, setNativeLocationEnabled] = useRecoilState(nativeLocationEnabledState);
  const [siderCollapsed, setSiderCollapsed] = useState(true);

  useEffect(() => {
    console.log("Read Count:",readCount);
  },[readCount])  
  
  
  const signOut = () => {
    setCurrentUser(null);
    auth.signOut()
    .then(() => {
      console.log('Signed Out');
      history.push('/');
    })
    .catch(e=>{
     console.error('Sign Out Error', e);
    });
    
  }

  
  

 /*  console.log('Current break point:',
        Object.entries(screens)
          .filter(screen => !!screen[1])
          .map(screen => screen[0])
          .join(' ')); */

  const getAuthenticatedUser = (id) => {
    //console.log("user id",id);
    firestore.collection("users").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                delete data.password;
                delete data.search_fields;
                data.id = id;
                setReadCount(p => p+1);
                setCurrentUser(p => ({...p, ...data}));
                
                //start geolocation tracking if user is currently working alone or emergency state
                if (Capacitor.getPlatform() !== 'web' && (data.workingAlone || data.emergencyBeacon)) {
                  startTracking(data.tracking_accuracy);
                }

                if (Capacitor.getPlatform() !== 'web') {
                  setupBackgroundGeoLocation(data.id, setCurrentLocation, setNativeLocationEnabled);
                }
                
                
                //console.log("User:",data);
                setCheckingAuth(false);
              } else {
                console.log("No such document!");
                message.error("Error getting User Data!");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Part");
            });
  }

  useEffect(() => {
    if (messaging) {
      navigator.serviceWorker.addEventListener("message", (message) => {
        console.log("notification",message.data['firebase-messaging-msg-data'].notification);
        const pushNotification = message.data['firebase-messaging-msg-data'].notification;
        notification.open({
            message: pushNotification.title,
            description: pushNotification.body,
            icon: <WarningTwoTone twoToneColor="#E03A3F"/>,
            duration: 0,
          
        })
      
      });
    }
  
  },[])
  
  useEffect(() => auth.onAuthStateChanged(async (user) => {
    if (user) {
      //console.log("Signed In",user);
        //setup background geolocation
        const idToken = await user.getIdToken(true);

        const idTokenResult = await user.getIdTokenResult();
        //console.log("claims",idTokenResult.claims);
        
       
        //console.log("idToken",idToken);
        //testFirestoreApi(idToken, user.uid);
        if (Capacitor.getPlatform() !== 'web') {
          //setupBackgroundGeoLocation(user.uid, setCurrentLocation, setNativeLocationEnabled);
          setupPushNotifications(user.uid,setCurrentUser);
          setCurrentUser(p => { return {...p, tokenId: idToken, tokenRefresh: user.refreshToken }});
        }

        getAuthenticatedUser(user.uid);
        
        if (messaging) {
          const messagingToken = await checkNotificationSubscription(user.uid);
          if (messagingToken) {
            setCurrentUser(p => { return {...p, messagingToken, tokenId: idToken, tokenRefresh: user.refreshToken }});
          }
        }

        //setup Firestore user listener
        unsubscribeUser = firestore.collection('users').doc(user.uid)
        .onSnapshot((doc) => {
            const user = doc.data();
            console.log('User changed in firestore listener');
            setReadCount(p => p + 1);
            setCurrentUser(p => ({...p, ...user}));
            if (Capacitor.getPlatform() !== 'web' && (!user.workingAlone && !user.emergencyBeacon)) {
                stopTracking();
            }
            
        })
      
     
    } else {
      //console.log("Not signed in");
      setCurrentUser(null);
      setCheckingAuth(false);
    }
  }),[]);

  let login = (<Login />);
  if (checkingAuth) {
    login = null;
  }
  

  
  let managerRoutes = null;
  if (currentUser && (currentUser.role === 'manager' || currentUser.role === 'admin')) {
    managerRoutes = ([
                    
                    <Route key="activityLog" path="/activityLog"  exact component = {ActivityLog} />,
                    <Route key="users" path="/users" exact component ={ListUsers} />,
                    <Route key="usersnew" path="/users/new" exact component ={UserForm} />,
                    <Route key="usersid" path="/users/:id" component ={UserForm} />,
                    <Route key="groups" path="/groups" exact component ={ListGroups} />,
                    <Route key="groupsnew" path="/groups/new" exact component ={GroupForm} />,
                    <Route key="groupsid" path="/groups/:id" component ={GroupForm} />,
                    
                    <Route key="liveDashboard" path="/liveDashboard"  exact component = {LiveDashboard} />,
                  ])
  }
  let adminRoutes = null;
  if (currentUser && currentUser.role === 'admin') {
    adminRoutes = ([
                    
                    <Route key="teams" path="/teams" exact component ={ListTeams} />,
                    <Route key="teamsnew" path="/teams/new" exact component ={TeamForm} />,
                    <Route key="teamsid" path="/teams/:id" component ={TeamForm} />,
                    <Route key="runFirestoreupdate" path="/runfirestoreupdate" component ={RunFirestoreUpdate} />

                  ])
  }


  let logoImage = <img className="logo" src={logo} style={{height: "45px"}} alt="City Of Steinbach Work Alone"/>;
  if (screens.xs) {
    logoImage = <img className="logo" src={mobileLogo} style={{height: "45px"}} alt="City Of Steinbach Work Alone"/>;
  }


  let defaultHomePage = 'checkin';
  if (currentUser && currentUser.role === 'admin') {
    defaultHomePage = 'liveDashboard';
  }

  const gotoPage = (page) => {
    if (!screens.md) {
      setSiderCollapsed(true);
    }
    history.push("/" + page);
  }

  let dev = null;
  if (environment === 'dev') {
    dev = <Tag color="error">Dev</Tag>;
  }

  let topPad = null;
  let headerHeight = '64';
  if (Capacitor.getPlatform() === 'ios') {
    topPad = (<div style={{width:'100%', height:20, background:'#ffffff'}}></div>);
    headerHeight = '84';
  }

  
//console.log("siderCollapsed",siderCollapsed);

let mobileSliderCss = null;
if (!screens.md) {
  mobileSliderCss = {position: "absolute", height: `calc(100% - ${headerHeight}px)`, zIndex:999999 };
}  
  return (
    <div className="App">
      {currentUser && !checkingAuth ? 
        <Layout style={{height:"100vh"}} >
          {topPad}
          <Header className="header no-print" style={{paddingRight:0}} >
          
          {logoImage}
          {dev}
          {/* {React.createElement(siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggleSider,
            })}  */}
         
            <Menu  mode="horizontal" style={{float:'right', top: 0, right: 0}}>
            <MenuItem key="currentuser"><UserOutlined />{currentUser.first_name}</MenuItem>
              <MenuItem key="signout" onClick={signOut}><LogoutOutlined />Sign Out</MenuItem>
              
            </Menu>
          </Header>
        <Layout>
      <Backdrop show={!siderCollapsed && !screens.md} clicked={() => setSiderCollapsed(true)}/>
      <Sider
        theme="light"
        breakpoint="md"
        collapsedWidth="0"
        //collapsible
        collapsed={siderCollapsed}
        className="no-print"
        //trigger={null}
        style={mobileSliderCss}
        onBreakpoint={broken => {
          //console.log("broken",broken);
          if (broken) {
            setSiderCollapsed(true);  
          } else {
            setSiderCollapsed(false);  
          }
          
        }}
        onCollapse={(collapsed, type) => {
          //console.log(collapsed, type);
          if (type === 'clickTrigger') {
            
            setSiderCollapsed(collapsed);
          }
        }}
      >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[history.location.pathname.split('/')[1] || defaultHomePage]}
            
            style={{ height: '100%', borderRight: 0 }}
          >
         
          <Menu.Item key="checkin" onClick={() => gotoPage('checkin')}> <SafetyCertificateOutlined />Check In</Menu.Item>
          
              
              { currentUser && (currentUser.role === 'manager' || currentUser.role === 'admin') ? 
                ([<Menu.Item key="liveDashboard" onClick={() => gotoPage('liveDashboard')}><RiMapPinUserLine style={{marginRight:10}} />Live Dashboard</Menu.Item>,
                  <Menu.Item key="activityLog" onClick={() => gotoPage('activityLog')}><FieldTimeOutlined />Activity Log</Menu.Item>,
                  <Menu.Item key="users" onClick={() => gotoPage('users')}><UserOutlined />Users</Menu.Item>,
                  <Menu.Item key="groups" onClick={() => gotoPage('groups')}><UsergroupAddOutlined />Groups</Menu.Item>,
                  
                  ])
              :
              null
              }

              { currentUser && currentUser.role === 'admin' ?
                (<Menu.Item key="teams" onClick={() => gotoPage('teams')}><LockOutlined />Teams</Menu.Item>)
              :
                null
              }

              <Menu.Item key="diagnostics" onClick={() => gotoPage('diagnostics')}> <SettingOutlined />Diagnostics</Menu.Item>
            
        
          </Menu>
        </Sider>
        <Layout className="main-layout" style={{}}>
       

         
        <Switch>
           {managerRoutes}
           {adminRoutes}
           
           <Route key="checkin" path="/checkin"  
                  exact 
                  render={(props) => <Checkin {...props} startTracking={startTracking} stopTracking={stopTracking} />} />,
           <Route key='diagnostics' path="/diagnostics" exact render={(props) => <Settings {...props} emailLog={emailLog} />} />
            {currentUser.role === 'admin' || currentUser.role === 'manager' ? 
              <Route path="/" exact component={LiveDashboard} />
            :
              <Route path="/" exact render={(props) => <Checkin {...props} startTracking={startTracking} stopTracking={stopTracking} />} />
            }
            <Redirect to="/" />
        </Switch>
          
        </Layout>
      </Layout>
    </Layout>
      :
      login
        
        
      }
    
      
      
    </div>
  );
}

export default App;

import React, {useState, useEffect} from 'react'
import {Button, Row, Col, Card, Form, Input, message, Avatar, List} from 'antd'
import {UsergroupAddOutlined} from '@ant-design/icons';
import {  checkNotificationSubscription, unsubscribeFromNotifications, fb, firestore } from '../firebase'
import { userState } from '../atoms'
import { useRecoilState } from 'recoil'
import { functions, environment, apiKey } from '../firebase'
import UserSelect from '../shared/UserSelect'
import pkg from '../../package.json'
import Axios from 'axios';



const callSendNotifications = functions.httpsCallable('sendNotifications');



const Settings = (props) => {

    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const {emailLog} = props;
    const [form] = Form.useForm();
    const [subStatusText, setSubStatusText] = useState("");
    const [group,setGroup] = useState({name: "", primary_contact:"", secondary_contact:""});
    const [loading, setLoading] = useState(false);

   

   /*  const subscribe = async () => {
        
        if (fb.messaging.isSupported()) {
            const token = await checkNotificationSubscription();
            setCurrentUser(p => { return {...p, messagingToken: token}});
        }
        
        
    } */

    const sendTestMessage = async (values) => {
       
        sendNotifications({recipient_id: values.recipient_id, body: values.message_text, title: `Message From ${currentUser.first_name} ${currentUser.last_name}`});
        message.success("Message Sent");
        form.resetFields();
    }

    const sendNotifications = async (message) => {
        const {recipient_id, body, title } = message;
        const recipient = {id: recipient_id};
        //const expiredWorkAloneUser = {first_name: 'Bob', last_name: 'Dole'};
        const data = {recipient, body, title};
        try {
            const response = await callSendNotifications(data);
            console.log("Response from Notification Cloud Function:",response);
            
        } catch(error) {
            console.log("Error sending notification",error);
        }
        

    }

   /*  const unsubscribe = () => {
        if (fb.messaging.isSupported()) {
            unsubscribeFromNotifications();
            setCurrentUser(p => { return {...p, messagingToken: null}});
        }
    } */

    //let text = "This device is currently unsubscribed from notifications";
    //let button = <Button block="true" size="large" onClick={subscribe}>Subscribe</Button>;
    
    useEffect(() => {
        console.log('[Settings.js] currentUser changed: messagingToken',currentUser.messagingToken);
        if (currentUser.messagingToken) {
        
            setSubStatusText("This device is subscribed to notifications");
            //button = <Button block="true" size="large" onClick={unsubscribe}>Unsubscribe</Button>;
        } else {
            setSubStatusText("This device is currently unsubscribed from notifications");
        }
    },[currentUser]);


    useEffect(() => {
        setLoading(true);
        firestore.collection("groups").doc(currentUser.group_id).get()
        .then((doc) => {
            const data = doc.data();
            
            setGroup({name: data.name, primary_contact: data.primary_contact_name, secondary_contact: data.secondary_contact_name })
            setLoading(false);
        })
        .catch((err) => {
            console.log("Error getting group details",err)
        })
    },[])

    const refreshUserToken = () => {
        const refreshUrl =  `https://securetoken.googleapis.com/v1/token?key=${apiKey}`;
        console.log('currentUser.tokenRefresh', currentUser.tokenRefresh);
        Axios.post(refreshUrl,{grant_type: 'refresh_token', refresh_token: currentUser.tokenRefresh})
          .then((response) => {
            console.log("refresh Token response", response);
          })
          .catch(error => {
              console.log('refresh Token error', error);
          })
      
      }


      const description = (
          <>
          <Row>
              1st Contact: {group?.primary_contact}
          </Row>
          <Row>
              2nd Contact: {group?.secondary_contact}
          </Row>
          </>
      )
    

    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '90vh'}}>
            <Col  style={{width: 350}}>
            
            <div style={{fontSize: 20, lineHeight: 1, textAlign: 'center', marginBottom: 20}}>{subStatusText}</div>
            <Card title="Send Test Notification" bordered={false} style={{width: '100%'}}>
                    <Form size="large" onFinish={sendTestMessage} form={form} initialValues={{recipient_id: undefined, message_text: null}}>
                        <Form.Item name='recipient_id' rules={[{ required: true, message: 'Please select recipient', }]}>
                            <UserSelect block='true' placeholder="Select Recipient" />
                            {/* <Select block='true' placeholder="Select Recipient">
                                {userSelectOptions}
                            </Select> */}
                        </Form.Item>
                        <Form.Item name='message_text' rules={[{ required: true, message: 'Please enter a message', }]}>
                            <Input.TextArea placeholder="Message Text" >

                            </Input.TextArea>
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Send Notification
                            </Button>
                        </Form.Item>

                       
                    </Form>
            </Card>
            <Card bordered={false} style={{marginTop: 20}} loading={loading}>
                <List itemLayout="horizontal" >
                    <List.Item>
                        <List.Item.Meta avatar={<Avatar icon={<UsergroupAddOutlined />} />} title={"My Group: " + group?.name} description={description} />
                    </List.Item>
                </List>
            </Card>
                <div style={{fontSize: 14, lineHeight: 1, textAlign: 'center', marginTop: 20}}>App Version: {pkg.version}</div>
                { environment === 'dev' ? <Button style={{marginTop: 20 }}block onClick={emailLog}>Email Geo Log</Button> : null }
               {/*  { environment === 'dev' ? <Button style={{marginTop: 20 }}block onClick={refreshUserToken}>Refresh User Token</Button> : null } */}
                
                   

               
                
            </Col>
           
        </Row>
    )
}

export default Settings;


export const debounce = (func, wait) => {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        const later = function() {
        timeout = null;
        func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
 };


export const convertToSearchFields = (fields) => {
    
    let searchFields = [];
   
    fields.forEach(field => {
      if (field) {
        const lowerCaseText = field.toLowerCase();
        let strChunks = [];
        //if field contains multiple words, split them up
        if (lowerCaseText.indexOf(' ') > 0) {
            strChunks = lowerCaseText.split(' ');
            strChunks.forEach(chunk => {
                searchFields.push(...createKeywords(chunk))
            })
        }
        
        searchFields.push(...createKeywords(lowerCaseText));
        //remove duplicates
        searchFields = [...new Set(searchFields)];
      }  
      
       
    })
    return searchFields;
}


const createKeywords = name => {
  const arrName = [];
  let curName = '';
  name.split('').forEach(letter => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
}


export const getUserLocation = (successFunction) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      successFunction,
      
      err => {
        console.log(`Error (${err.code}): ${getPositionErrorMessage(err.code)}`);
      }
    );
  } else {
    alert('Geolocation is not supported by your browser.');
  }
}

export const getPositionErrorMessage = code => {
  switch (code) {
    case 1:
      return 'Permission denied.';
    case 2:
      return 'Position unavailable.';
    case 3:
      return 'Timeout reached.';
  }
}

let timeoutIds = {};


export const watchPosition = (callback, errorCallback, options) => {
  var throttleTime = (!options ? 0 : options.throttleTime || 0);
  var bufferedArguments = null;
  var lastCall = null;
  var timeoutId = null;
  var watchId = null;
  
  watchId = navigator.geolocation.watchPosition(function() {
      // update bufferedArguments
      bufferedArguments = arguments;
      
      if (!lastCall) {
          //console.log("calling immediately initially");
          lastCall = new Date();
          callback.apply(this, arguments);
      } else if (!timeoutId) {
          // check if we've already passed the buffer time, in which case 
          // we'll call the callback immediately
          if (new Date()-lastCall > throttleTime) {
              //console.log("calling immediately since time has already passed");
              lastCall = new Date();
              callback.apply(this, arguments);
          } else {
              // if not enough time has passed since the last callback, we'll schedule
              // a callback in the future
              var that = this;
              //console.log("call scheduled");
              timeoutId = setTimeout(function() {
                  //console.log("Call");
                  lastCall = new Date();
                  callback.apply(that, bufferedArguments);
                  
                  timeoutId = null;
                  timeoutIds[watchId] = null;
              }, throttleTime - (new Date()-lastCall));
              
              // we store the timeout id so that we can clear the timeout if clearWatch 
              // is called before the setTimeout fires
              timeoutIds[watchId] = timeoutId;
          }
      } else {
          // we already have a scheduled call
          //console.log("skipping call");
      }
  }, errorCallback, options);
  return watchId;
}

/**
* Calls navigator.geolocation.clearWatch for the given watchId, but also 
* clears any timeout that has been set up by GeolocationThrottle to make 
* sure that no more callback for this watchId is called.
*/
export const stopWatchingPosition = (watchId) => {
  navigator.geolocation.clearWatch(watchId);
  
  // if there's a scheduled watch position callback we'll clear it
  var timeoutId = timeoutIds[watchId];
  if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutIds[watchId] = null;
  }
}




import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, message,  Select} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp } from '../firebase'
import {convertToSearchFields} from '../shared/utilities'
import UserSelect from '../shared/UserSelect'
import TeamSelect from '../shared/TeamSelect'
import { useRecoilState } from 'recoil';
import { userState } from '../atoms';


function GroupForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const location = useLocation();
    const [loading,setLoading] = useState(false);
    const [title, setTitle] = useState('New Group');
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const [formChanged, setFormChanged] = useState(false);
    const [secondaryContactValidateStatus, setSecondaryContactValidateStatus] = useState("success");
    const {Option} = Select;
    
    

 
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };

        
      useEffect(() => {
        //Get document from Firebase if id exists as a param
        console.log("id",id);
        if (id) {
          if (location.state) {
            console.log("getting data from location.state");
            setTitle(location.state.name);
            const data = location.state;
            delete data.id;
            
            form.setFieldsValue(data);
          } else {
            console.log("Fetching from Firestore");
            firestore.collection("groups").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                form.setFieldsValue(data);
                setTitle(data.name);
              } else {
                console.log("No such document!");
                message.error("Could not load Deparment");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Deparment");
            });
          }
       
        } //if (id)

        
      },[id,form,location.state])

     
           
      const onFinish = async values => {
        console.log('Values at beginning of onFinish:', values);
        values.search_fields = convertToSearchFields([values.name]);
        const primary_contact = values.primary_contact;
        values.primary_contact_id = primary_contact.value;
        values.primary_contact_name = primary_contact.label;
        
        const secondary_contact = values.secondary_contact;
        values.secondary_contact_id = secondary_contact.value;
        values.secondary_contact_name = secondary_contact.label;

        if (currentUser.role !== 'admin') {
          const team_id =  currentUser.team_id;
          const teamRef = await firestore.collection('teams').doc(team_id).get();
          const team_name = teamRef.data().name;
          values.team_id = team_id;
          values.team_name = team_name;
          values.team = {
            key: team_id,
            label: team_name,
            value: team_id
          }
        } else {
          values.team_id = values.team.value;
          values.team_name = values.team.label;
        }
       
        
        console.log("Values before posting to Firebase",values)
        //values contains form data to be submitted
        
        sendToFirebase(values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        //console.log("Values before posting to Firebase",values)
        //create parts_ids array
       
        
        if (id) {
          //Update existing item
          firestore.collection('groups').doc(id).set({...values}, {merge: true})
          .then(function() {
            setFormChanged(false);
            message.success('Group Updated!');
            history.push('/groups');
            
          })
          .catch(function(error) {
              console.error("Error updating group ", error);
          });

        } else {
          //Add new item
          values.created = serverTimestamp();
          firestore.collection('groups').add({...values})
          .then(function() {
            setFormChanged(false);
            message.success(`Added Group: ${values.name}`);
            history.push('/groups');
          })
          .catch(function(error) {
              console.error("Error creating group: ", error);
          });
        }

      }


      
      const onValuesChange = () => {
        
        const values = form.getFieldsValue();
        console.log("OnValuesChange triggered",values);
       /*  if (values.primary_contact.value === values.secondary_contact.value) {
          console.log("duplicate!");
          setSecondaryContactValidateStatus("error");
        } else {
          setSecondaryContactValidateStatus("success");
        } */
        setFormChanged(true);
      }

      

      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" loading={loading} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }
      
    return (
        <div>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Groups</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'Group: ' + title}
                extra={[
                    saveButton,
                    <Button key="close" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            size="large"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            
            initialValues={{ 
              name: null,
              primary_contact: {},
              secondary_contact: {}
            }}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                        <Form.Item
                            label="Name"
                            name="name"
                            className=""
                            rules={[{required: true, message: 'Please input a group name'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        { currentUser.role === 'admin' ?
                          <Form.Item label='Team' name='team' rules={[{required: true, message: 'Please select a team'}]}>
                              <TeamSelect labelInValue />
                          </Form.Item>
                          :
                          null
                        }
                        <Form.Item
                            label="Primary Contact"
                            name="primary_contact"
                            className=""
                            rules={[{required: true, message: 'Please select a primary contact'}]}
                            
                        >
                            <UserSelect labelInValue placeholder="Select Primary Contact"/>
                        </Form.Item>
                       
                       <Form.Item
                            label="Secondary Contact"
                            name="secondary_contact"
                            className=""
                            //validateStatus={secondaryContactValidateStatus}
                            //help="Cannot be the same contact for both"
                            rules={[{required: true,
                                     message: 'Please select a secondary contact'
                                    },
                                    ({getFieldValue}) => ({
                                      validator(rule, value) {
                                        if (value.value === getFieldValue('primary_contact').value) {
                                          return Promise.reject('Primary and Secondary must be different');
                                        }
                                       
                                        return Promise.resolve();
                                      },
                                    })
                                  ]}
                            
                        >
                            <UserSelect labelInValue placeholder="Select Secondary Contact" />
                        </Form.Item>
                       
                        
                       
                    </Col>
                   
                </Row>
               
            </Form>
        </Content>
            
        </div>
    )
}

export default GroupForm



import React, {memo} from 'react';
import './Marker.css';
import {Tag} from 'antd'

const Marker = memo((props) => {
    const { color, name, id } = props;
    return (
      <div style={{marginTop:-45}}>
          <Tag style={{marginTop:-45, marginLeft:-50, padding: "2px 5px", fontSize:18, fontWeight:'bold'}}>{name}</Tag>
      <div>
         
        <div
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={name}
        />
        <div className="pulse" />
      </div>
      </div>
    );
  });

  export default Marker;
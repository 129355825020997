import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, message,  Select} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons';
import { firestore, serverTimestamp } from '../firebase'
import {convertToSearchFields} from '../shared/utilities'
import UserSelect from '../shared/UserSelect'


function TeamForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const location = useLocation();
    const [loading,setLoading] = useState(false);
    const [title, setTitle] = useState('New Team');
    const [formChanged, setFormChanged] = useState(false);
    const [secondaryContactValidateStatus, setSecondaryContactValidateStatus] = useState("success");
    const {Option} = Select;
    
    

 
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };

        
      useEffect(() => {
        //Get document from Firebase if id exists as a param
        console.log("id",id);
        if (id) {
          if (location.state) {
            console.log("getting data from location.state");
            setTitle(location.state.name);
            const data = location.state;
            delete data.id;
            
            form.setFieldsValue(data);
          } else {
            console.log("Fetching from Firestore");
            firestore.collection("teams").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                form.setFieldsValue(data);
                setTitle(data.name);
              } else {
                console.log("No such document!");
                message.error("Could not load Deparment");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Deparment");
            });
          }
       
        } //if (id)

        
      },[id,form,location.state])

     
           
      const onFinish = values => {
        console.log('Values at beginning of onFinish:', values);
        values.search_fields = convertToSearchFields([values.name]);
       
        
       
        
        console.log("Values before posting to Firebase",values)
        //values contains form data to be submitted
        
        sendToFirebase(values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        //console.log("Values before posting to Firebase",values)
        //create parts_ids array
       
        
        if (id) {
          //Update existing item
          firestore.collection('teams').doc(id).set({...values}, {merge: true})
          .then(function() {
            setFormChanged(false);
            message.success('Team Updated!');
            history.push('/teams');
            
          })
          .catch(function(error) {
              console.error("Error updating team ", error);
          });

        } else {
          //Add new item
          values.created = serverTimestamp();
          firestore.collection('teams').add({...values})
          .then(function() {
            setFormChanged(false);
            message.success(`Added Team: ${values.name}`);
            history.push('/teams');
          })
          .catch(function(error) {
              console.error("Error creating team: ", error);
          });
        }

      }


      
      const onValuesChange = () => {
        
        const values = form.getFieldsValue();
        console.log("OnValuesChange triggered",values);
       /*  if (values.primary_contact.value === values.secondary_contact.value) {
          console.log("duplicate!");
          setSecondaryContactValidateStatus("error");
        } else {
          setSecondaryContactValidateStatus("success");
        } */
        setFormChanged(true);
      }

      

      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" loading={loading} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }
      
    return (
        <div>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Teams</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'Team: ' + title}
                extra={[
                    saveButton,
                    <Button key="close" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            size="large"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            
            initialValues={{ 
              name: null,
              primary_contact: {},
              secondary_contact: {}
            }}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                        <Form.Item
                            label="Name"
                            name="name"
                            className=""
                            rules={[{required: true, message: 'Please input a team name'}]}
                        >
                            <Input />
                        </Form.Item>
                        
                    </Col>
                   
                </Row>
               
            </Form>
        </Content>
            
        </div>
    )
}

export default TeamForm



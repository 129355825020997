import BackgroundGeolocation from "cordova-background-geolocation";
import { Capacitor } from "@capacitor/core";
import { projectId, apiKey, getCurrentUser } from "../firebase";

const highAccuracy = {
  preventSuspend: true,
  distanceFilter: 20,
  stationaryRadius: 20,
  locationUpdateInterval: 20000, //Android only
  //stopTimeout: 60, //Minutes to wait in moving state with no movement before considering the device stationary. Default 5
  disableStopDetection: true, //Disables stop-detection system. locations services will run until turned off
  pausesLocationUpdatesAutomatically: false, //iOS only. location services will never be turned off
  locationAuthorizationRequest:"Any", //We are okay with both "Always" or "When In Use"
}

const batterySaving = {
  preventSuspend: false,
  distanceFilter: 50,
  stationaryRadius: 50,
  locationUpdateInterval: 30000, //Android only
  //stopTimeout: 60, //Minutes to wait in moving state with no movement before considering the device stationary. Default 5
  disableStopDetection: false, //Disables stop-detection system. locations services will run until turned off
  pausesLocationUpdatesAutomatically: true, //iOS only. location services will never be turned off
  locationAuthorizationRequest:"Always", //We are okay with both "Always" or "When In Use"
}


export const setupBackgroundGeoLocation = (user_id, setCurrentLocation, setNativeLocationEnabled) => {
    let logLevel;
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    logLevel = BackgroundGeolocation.LOG_LEVEL_ERROR;
  } else {
    logLevel = BackgroundGeolocation.LOG_LEVEL_VERBOSE;
  }


  const defaultSettings = {
    reset: true,
    debug: false,
    logLevel: logLevel,
    desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
    //distanceFilter: 10,
    //stationaryRadius: 10,
    //stopTimeout: 60, //Minutes to wait in moving state with no movement before considering the device stationary. Default 5
    //disableStopDetection: true, //Disables stop-detection system. locations services will run until turned off
    //pausesLocationUpdatesAutomatically: false, //iOS only. location services will never be turned off
    
    //preventSuspend: true, //iOS only, recommend not to use

    showsBackgroundLocationIndicator: true, //iOS only
    locationUpdateInterval: 10000, //Android only
    foregroundService: true,
    notification: {
      title: "COS Work Alone",
      text: "Background Location Tracking Enabled",
    },
    url: `https://firestore.googleapis.com/v1beta1/projects/${projectId}/databases/(default)/documents/users/${user_id}/?updateMask.fieldPaths=lastLocation&updateMask.fieldPaths=lastLocationUpdate`,
    locationTemplate:
      '{"fields": { "lastLocation" : { "mapValue": { "fields": { "lat": { "doubleValue": "<%= latitude %>" }, "lng": { "doubleValue": "<%= longitude %>"}}}}, "lastLocationUpdate": {"timestampValue": "<%= timestamp %>"}}}',
    httpRootProperty: ".",
    //headers: {"authorization": `Bearer ${token}`},

    method: "PATCH",
    //test: {fields: { lastLocation : { mapValue: { fields: { "lat": { doubleValue: 49.55 }, "lng": { doubleValue: -96.777 }}}}, lastLocationUpdate: {timestampValue: "t"}}},
    autoSync: true,
    stopOnTerminate: true,
    startOnBoot: false,
  }


 


  
  
  
  if (Capacitor.getPlatform() !== "web") {
    // 1.  Listen to events.
    BackgroundGeolocation.onLocation(
      (location) => {
        console.log("[location] - ", location);
        setCurrentLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        });
      },

      (error) => {
        console.log("[location] ERROR", error);
        setCurrentLocation("NONE");
      }
    );
    /*  console.log('attempting background update of firestore');
          firestore.collection('users').doc(user_id).set({lastLocation: {lat: location.coords.latitude, lng: location.coords.longitude, activity: location.activity.type} , lastLocationUpdate: serverTimestamp()},{merge: true})
                .then(() => { console.log("updated user location in firestore in background")})
                .catch((error) => console.log("Error updating user", error.message)) */

    BackgroundGeolocation.onMotionChange((event) => {
      console.log("[motionchange] - ", event.isMoving, event.location);
    });

    BackgroundGeolocation.onHttp((response) => {
      console.log(
        "[http] - ",
        response.success,
        response.status,
        response.responseText
      );
    });

    BackgroundGeolocation.onProviderChange((event) => {
      console.log(
        "[providerchange] - ",
        event.enabled,
        event.status,
        event.gps
      );

      //show location alert info if permissions have changed
      //localStorage.setItem('alertShown', false);
      Storage.set({key: 'alertShown', value: 'no'});

      // (!event.enabled && event.status === 4 && event.gps === true) this occurs in iOS when location is on but set to "when in use"
      // event.status === 0 occurs on iOS when location permissions have not yet been determined (before app requests location permission for the first time)
      if (event.enabled || (!event.enabled && event.status === 4 && event.gps === true) || (event.status === 0)) {
        setNativeLocationEnabled(true);
      } else {
        setNativeLocationEnabled(false);
      }
      

      
     
    });

    BackgroundGeolocation.onAuthorization((event) => {
      if (event.success) {
        console.log("[authorization] SUCCESS: ", event.response);
      } else {
        console.log("[authorization] ERROR: ", event.error);
      }
    });



    //console.log("Providing refreshToken to BGeo.ready", refreshToken);
    // 2.  Configure the plugin with #ready
    BackgroundGeolocation.ready(
      defaultSettings,
      (state) => {
        console.log("[ready] BackgroundGeolocation is ready to use");
        BackgroundGeolocation.destroyLocations().then(() => {
          console.log("Destroyed locations in SQLITE DB");
        });
        if (!state.enabled) {
          // 3.  Start tracking.
          //BackgroundGeolocation.start();
        }
      }
    );
  } //if Capacitor.getPlatform
};

export const startTracking = async (trackingAccuracy) => {
  console.log("start background geolocation tracking");
  
  
  
  
  //refresh user token so native code can use it in background for 1 hr to post updates
  const authUser = await getCurrentUser();
  const token = await authUser.getIdToken(true);
  const refreshToken = authUser.refreshToken;


  const defaultSettings = {
    
    authorization: {
      strategy: "JWT",
      accessToken: token,
      //accessToken: 'bogusTokenForcerefresh',
      refreshToken: refreshToken,
      refreshUrl: `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
      refreshPayload: {
        refresh_token: "{refreshToken}",
        grant_type: "refresh_token",
      },
      expires: 3600,
    },
  }

  let settings = null;
  switch (trackingAccuracy) {
    case "high":
      settings = {...defaultSettings, ...highAccuracy};
      break;
    case "medium":
      settings = {...defaultSettings, ...batterySaving};
      break;
    default:
      //default to high accuracy if none specified
      settings = {...defaultSettings,...highAccuracy};

  }

  await BackgroundGeolocation.setConfig(settings);
  const state = await BackgroundGeolocation.start();
  BackgroundGeolocation.changePace(true);
  console.log("[start] tracking success - ", state);
};

export const stopTracking = () => {
  console.log("stopping background geolocation tracking");
  BackgroundGeolocation.setConfig({ preventSuspend: false }).then((state) => {
    BackgroundGeolocation.stop();
  });
};

export const getCurrentPosition = async () => {
  let results = await BackgroundGeolocation.getCurrentPosition({
    timeout: 30,          // 30 second timeout to fetch location
    persist: true,        // Defaults to state.enabled
    maximumAge: 10000,     // Accept the last-known-location if not older than 5000 ms.
    desiredAccuracy: 10,  // Try to fetch a location with an accuracy of `10` meters.
    samples: 3           // How many location samples to attempt.
   
  });

  let currentPosition = {
      lat: results.coords.latitude,
      lng: results.coords.longitude,
  }

  return currentPosition;
}

export const emailLog = () => {
  BackgroundGeolocation.logger
    .emailLog("louisplett@gmail.com")
    .then((success) => {
      console.log("[emailLog] SUCCESS");
    })
    .catch((error) => {
      console.log("[emailLog] ERROR: ", error);
    });
};

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/messaging';
import Bowser from 'bowser';




const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY_DEV,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
  databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
  projectId: process.env.REACT_APP_PROJECT_ID_DEV,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
  appId: process.env.REACT_APP_APP_ID_DEV,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV,
  webPushCertificateKey: process.env.REACT_APP_WEB_PUSH_CERTIFICATE_KEY_DEV,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV,
  environment: "dev"
};

//console.log('measurementID:',process.env.REACT_APP_MEASUREMENT_ID_DEV);
 const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY_PROD,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
  databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
  projectId: process.env.REACT_APP_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_APP_ID_PROD,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD,
  webPushCertificateKey: process.env.REACT_APP_WEB_PUSH_CERTIFICATE_KEY_PROD,
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PROD,
  environment: "prod"
 }
  const config =
  process.env.REACT_APP_ENVIRONMENT === 'prod' ? prodConfig : devConfig; 
 

 //console.log("hostname",window.location.hostname);

 //const config = devConfig;

firebase.initializeApp(config);
firebase.analytics();
let fbmessaging = null;
if (firebase.messaging.isSupported()) {
  fbmessaging = firebase.messaging();
  fbmessaging.usePublicVapidKey(config.webPushCertificateKey);
}

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const messaging = fbmessaging;
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firestoreDate = firebase.firestore.Timestamp;
export const auth = firebase.auth();
export const functions = firebase.functions();
export const fb = firebase;
export const googleMapsKey = config.googleMapsKey;
export const projectId = config.projectId;
export const environment = config.environment;
export const apiKey = config.apiKey;
export const getCurrentUser = () => {
  return new Promise((res, rej) => {
    const unsubscribe = auth.onAuthStateChanged(curUser => {
      unsubscribe();
      res(curUser);
    }, rej);
  });
};

let cns = null;
if (firebase.messaging.isSupported()) {
  cns = async (user_id) => {
    try {
      const token = await messaging.getToken();
      
      //console.log("This Device Token:",token);
      const storedTokens = await firestore.collection('tokens').where('token','==',token).where('user_id','==',user_id).get(); 
      console.log("storedTokens",storedTokens.docs.length);
      if (storedTokens.docs.length > 0) {
        //this is already in the system
        console.log('token already in firestore');
        
      } else {
        console.log('adding token to firestore');
        const browser = Bowser.getParser(window.navigator.userAgent);
        console.log("Browser:",browser.parsedResult);
        await firestore.collection('tokens').add({user_id: user_id, token: token, device: browser.parsedResult, created: serverTimestamp()})
      }
      return token;
    } catch(error) {
      console.log("Error checking subscription", error);
      return null;
    }
  }
} else { console.log("firebase messaging not supported")}
export const checkNotificationSubscription = cns;

let ufn = null;
if (firebase.messaging.isSupported()) {
  ufn = async () => {
    //let tokenToRemove = null;
    try {
      const token = await messaging.getToken();
      //tokenToRemove = token;
      const response = await messaging.deleteToken(token);
      const ref = await firestore.collection('tokens').where('token','==',token).get();
      if (ref.docs.length > 0) {
        const id = ref.docs[0].id;
        console.log("deleting token",id);
        await firestore.collection('tokens').doc(id).delete();
      }
    } catch(error) {
      console.log("Error in unsubscribeFromNotifications",error);
    }
  }
}

export const unsubscribeFromNotifications = ufn;




import React, {useState, useEffect} from 'react'
import {firestore} from '../firebase'
import {Select} from 'antd'
import { useRecoilState } from 'recoil'
import { userState } from '../atoms'

const UserSelect = (props) => {
    
    const [userSelectOptions, setUserSelectOptions] = useState([]);
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    //const {placeholder, selectChanged} = props;

    useEffect( () => {
        async function fetchUsers() {
            let userQuery = firestore.collection('users').orderBy('last_name');
            if (currentUser.role !== 'admin') {
                userQuery = userQuery.where('team_id','==',currentUser.team_id);
            }
            const userRefs = await userQuery.get();
            setUserSelectOptions(userRefs.docs.map(user => {
                                    return (<Select.Option key={user.id} value={user.id}>{user.data().last_name + ", " + user.data().first_name}</Select.Option>)
                                        }
                                ));
        }

        fetchUsers();

       
    }, [])
    
    return (
        <Select {...props}>
            {userSelectOptions}
        </Select>
    )
}

export default UserSelect;

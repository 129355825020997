import React, {useEffect, useState} from 'react'
import {  Button,  Row, Col, Statistic, notification, Modal} from 'antd';
import mobileLogo from '../assets/logoMobile.png'
import Switch from 'react-switch';
import { AlertFilled, WarningTwoTone, ExclamationCircleOutlined} from '@ant-design/icons';
import { GoLocation } from "react-icons/go";
import { IoIosPulse } from "react-icons/io";
import { MdLocationOff } from "react-icons/md";
import { firestore, functions, serverTimestamp, fb, projectId } from '../firebase';
import {watchPosition, stopWatchingPosition} from '../shared/utilities';
import {locationState, firestoreReadCount, userState, nativeLocationEnabledState} from '../atoms';
import moment from 'moment';
import './Checkin.css';
import { useRecoilState } from 'recoil';
import Axios from 'axios';
import CheckinFrequencyForm from '../shared/CheckinFrequencyForm'
import {Capacitor} from '@capacitor/core';
import {getCurrentPosition} from '../BackgroundGeoLocation/BackgroundGeoLocation'
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;


const { Countdown } = Statistic;
const { confirm} = Modal;

const callScheduleNotification = functions.httpsCallable('scheduleNotification');
const callClearPendingUserNotifications = functions.httpsCallable('clearPendingUserNotifications');
const callClearPendingUserEmergencyNotifications = functions.httpsCallable('clearPendingUserEmergencyNotifications');

let watchId = null;
let unsubscribeUser = null;

/* const trackLocation = ({ onSuccess, onError = () => { } }) => {
    if ('geolocation' in navigator === false) {
      return onError(new Error('Geolocation is not supported by your browser.'));
    }
  
    // Use watchPosition instead.
    return navigator.geolocation.watchPosition(onSuccess, onError);
  }; */
  

const Checkin = (props) => {
    const [checkedIn, setCheckedIn] = useState(false);
    const [timeExceeded, setTimeExceeded] = useState(false);
    const [deadline, setDeadline] = useState(null);
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const [currentLocation, setCurrentLocation] = useRecoilState(locationState);
    const [nativeLocationEnabled, setNativeLocationEnabled] = useRecoilState(nativeLocationEnabledState);
    const [readCount, setReadCount] = useRecoilState(firestoreReadCount);
    const [shouldLogActivity, setShouldLogActivity] = useState(false);
    const [location, setLocation] = useState("");
    //const [locationChangedCounter, setLocationChangedCounter] = useState(0);
    const [emergencyActive, setEmergencyActive] = useState(false);
    const [showCheckinModal, setShowCheckinModal] = useState(false);
    const [deactivating, setDeactivating] = useState(false);
    const [backgroundLocationAlertShown, setBackgroundLocationAlertShown] = useState(false);
    const {startTracking, stopTracking} = props;
    
    
    
    
    useEffect(() => {
        //check if we've shown user background location info alert
        //const alertShown = localStorage.getItem('alertShown');
        const getAlertShownFromStorage = async () => {
            const alertShown = await Storage.get({key: 'alertShown'});
            if (alertShown.value === 'yes') {
                setBackgroundLocationAlertShown(true);
            } else {
            }
    
        }

        getAlertShownFromStorage();
        
       
        
    },[])
    
    useEffect(() => {
        console.log("[Checkin.js] Current User:",currentUser);
        if (currentUser.workingAlone) {
            setCheckedIn(true);
            const deadlineDate = currentUser.deadline.toDate();
            if (deadlineDate < new Date()) {
                setTimeExceeded(true);
            }
            setDeadline(moment(currentUser.deadline.toDate()));
            
        } else {
            setDeadline(null);
            setCheckedIn(false);
            setTimeExceeded(false);

        }

        if (currentUser.emergencyBeacon) {
            setEmergencyActive(true);
        } else {
            setEmergencyActive(false);
        }

        if (currentUser.emergencyBeacon || currentUser.workingAlone) {
           //don't watch position on web anymore
            /*  
            //setup watch position
            //don't watch position on Android / IOS native
            if (Capacitor.getPlatform() === 'web') {
                //web only code
                if (watchId) { stopWatchingPosition(watchId);}
                watchId = watchPosition(position => {
                    const location = {lat: position.coords.latitude, lng: position.coords.longitude };
                    console.log("Position Changed:",location);
                    // setLocation(location);
                    //setLocationChangedCounter(p => p +1);
                
                    firestore.collection('users').doc(currentUser.id).set({lastLocation: location, lastLocationUpdate: serverTimestamp()},{merge: true})
                    .then(() => { console.log("updated user location in firestore")})
                    .catch((error) => console.log("Error updating user", error.message))
                    //logActivity('checkin',location);
                },
                () => {
                    //error function  
                    console.log("Error watching position")
                },
                { 
                    throttleTime: 30000, // check position every 30 seconds
                }
                );
            } else {
                //native only code
                //startTracking();

            }  
        */
            
        }

        return(() => {
            //if (watchId) { stopWatchingPosition(watchId);}
        })
    },[currentUser])


    useEffect(() => {
        console.log("[currentLocation] changed", currentLocation);
        console.log("[currentLocation] shouldLogActivity", shouldLogActivity);
        if (shouldLogActivity) {
            console.log("logging activity from useEffect(currentlocation)", shouldLogActivity);
            logActivity(shouldLogActivity);
            setShouldLogActivity(false);
        }
    },[currentLocation]);


   
    const logActivity = async (type, payload) => {
        
        if (type) {
            
            let record = {
                type: type,
                user_id: currentUser.id,
                team_id: currentUser.team_id,
                time: serverTimestamp(),
                //location: location,
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                 
            };

            console.log("Logging Activity",record);
    
            if (type === 'checkin' || type === 'refresh') {
                console.log("Type",type);
                let expireTime;
                if (payload?.expireTime) {
                    expireTime = payload.expireTime;
                } else {
                    expireTime = deadline;
                }
                record.deadline = fb.firestore.Timestamp.fromDate(expireTime.toDate());
                record.checkin_frequency = currentUser.checkin_frequency;
                if (currentLocation != 'NONE') {
                    record.location = currentLocation;
                    console.log("currentLocation", JSON.stringify(currentLocation));
                }
                
            }

            if (type === 'emergencyActivate' || type === 'deactivate' || type === 'emergencyDeactivate') {
                if (payload) {
                    record.location = payload;
                } else if (currentLocation) {
                    record.location = currentLocation;
                }
            }

            
           
            
    
            try {
                await firestore.collection('activityLog').add(record);
            } catch(error) {
                console.log("Error adding ActivityLog to Firebase", error.message);
            }
        }
        
    }

    const confirmBeforeStart = async (action) => {
        if (backgroundLocationAlertShown === false && Capacitor.getPlatform() !== 'web') {
            Modal.warning({
                title: <div style={{textAlign:'center'}}>CoS Work Alone uses Background Geolocation</div>,
                icon: <div style={{textAlign:'center', marginBottom:20}}><img src={mobileLogo} style={{height:60}} /></div>,
                content: (<div style={{textAlign:'center'}}>
                            <p>
                                CoS Work Alone collects location data to enable the sharing of your location to your emergency contacts while working alone. It runs even when the app is closed or not in use.
                            </p>
                            <p>
                                Location data is only collected while your timer is running or your Emergency Beacon is enabled.
                            </p>
                          </div>),
                onOk() {
                    Storage.set({key: 'alertShown', value: 'yes'});
                    //localStorage.setItem('alertShown', true);
                    setBackgroundLocationAlertShown(true);
                    action();
                }
                


            })    
        } else {
            action();
        }
    }

    const startTimer = async () => {

        
        
        const expireTime = moment(serverTimestamp().toDate).add(currentUser.checkin_frequency, 'minutes');
        const reminderTimeInSeconds = expireTime.clone().subtract(5, 'minutes').unix();
        const expireTimeInSeconds = expireTime.clone().unix();
        const fsDeadline = fb.firestore.Timestamp.fromDate(expireTime.toDate())
        setDeadline(expireTime);
        if (Capacitor.getPlatform() !== 'web') {
            //startTracking();
            
        }
        
        
        setTimeExceeded(false);

        //update user in firestore once we have initial location
        const checkinLocationSuccess = async (pos) => {
            const lastLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
            setCurrentLocation(lastLocation);
            console.log("Start Timer location", lastLocation);
            if (currentUser.workingAlone) {
                //logActivity('refresh', {expireTime, lastLocation});
                setShouldLogActivity('refresh');

            } else {
                //logActivity('checkin', {expireTime, lastLocation});
                setShouldLogActivity('checkin');
            }
            firestore.collection('users').doc(currentUser.id).set({workingAlone: true, 
                                                                    deadline: fsDeadline,
                                                                    lastCheckinTime: serverTimestamp(),
                                                                    lastLocation: lastLocation,
                                                                    lastLocationUpdate: serverTimestamp()
                                                                },
                                                                {merge: true})
                                                            .then(() => { return null;})
                                                            .catch((error) => {console.log('Error updating user',error)});
           
        }
        //if we can't get location, still update firestore, but without location data
        const checkinLocationError = async (err) => {
            notification.open({
                message: "Unable to Get Your Location",
                description: "You may have disabled location services on this device",
                icon: <MdLocationOff />,
                duration: 0,
              
            });
            
            console.warn(`ERROR(${err.code}): ${err.message}`);
            if (currentUser.workingAlone) {
                logActivity('refresh', {expireTime, lastLocation:null});
            } else {
                logActivity('checkin', {expireTime, lastLocation:null});
            }
            firestore.collection('users').doc(currentUser.id).set({workingAlone: true, 
                deadline: fsDeadline,
                lastCheckinTime: serverTimestamp()
            },
            {merge: true})
        .then(() => { return null;})
        .catch((error) => {console.log('Error updating user',error)});
        }
        //console.log("retrieving location");
        
        if (Capacitor.getPlatform() === 'web') {
            navigator.geolocation.getCurrentPosition(checkinLocationSuccess, checkinLocationError, {timeout: 10000, maximumAge: 0, enableHighAccuracy:true});
        } else {
            //we are on native. Setup working alone without location data
            const activityType = currentUser.workingAlone ? 'refresh' : 'checkin'
            console.log("activity type:", activityType);
            if (nativeLocationEnabled) {
                startTracking(currentUser.tracking_accuracy);
                setShouldLogActivity(activityType);
            } else {
                notification.open({
                    message: "Unable to Get Your Location",
                    description: "You may have disabled location services on this device",
                    icon: <MdLocationOff />,
                    duration: 0,
                  
                });
                logActivity(activityType,{expireTime, lastLocation:null});
            }
            
            
            firestore.collection('users').doc(currentUser.id).set({workingAlone: true, 
                deadline: fsDeadline,
                lastCheckinTime: serverTimestamp()
            },
            {merge: true})
            .then(() => { return null;})
            .catch((error) => {console.log('Error updating user',error)});

        }
        
        //schedule notification
        let messageData = {
            payload: {
                notificationType: 'reminder',
                workalone_user_id: currentUser.id,
                group_id: currentUser.group_id,
                body: 'Your timer will expire in 5 minutes. Please check-in or deactivate', 
                title: 'Work Alone Reminder'
            },
            deliverMessageAtSeconds: reminderTimeInSeconds
            
        }
        
       
        if (currentUser.workingAlone) {
            
            callClearPendingUserNotifications({user_id: currentUser.id})
                .then((response) => {
                    console.log("Response from refresh clearPending",response);
                    callScheduleNotification(messageData);
                    //callScheduleNotification(expiredMessageData);
                })
                .catch(() => {
                    console.log("error scheduling refresh reminders");
                })
            //wait until previous notifications are cleared before adding new ones. 
            
            try {
              
                
            } catch(error) {
                console.log("Error Scheduling Reminder",error);
            }
    
        } else {
            
            try {
                callScheduleNotification(messageData).then(() => { return null});
                //callScheduleNotification(expiredMessageData).then(() => { return null});
                
            } catch(error) {
                console.log("Error Scheduling Reminder",error);
            }
        }
        setCurrentUser((p) => ({...p, workingAlone: true, deadline: fsDeadline}));
        setCheckedIn(true);
    }

  
    const stopTimer = async () => {
        
        setDeactivating(true);
        //setDeadline(null);
        //setCheckedIn(false);
        //setTimeExceeded(false);
        

        //get location for log
        const deactivateLocationSuccess = async (pos) => {
            const lastLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
            setCurrentLocation(lastLocation);
            console.log("Stop Timer location", lastLocation);
             setShouldLogActivity('deactivate');
            
            firestore.collection('users').doc(currentUser.id).set({workingAlone: false, 
                                                                   reminder_count: null,
                                                                   expired_count: null,
                                                                   lastLocation: lastLocation,
                                                                   lastLocationUpdate: serverTimestamp()
                                                                },
                                                                {merge: true})
                                                            .then(() => { setDeactivating(false);})
                                                            .catch((error) => {console.log('Error updating deactivated user',error)});
            
        }
        //if we can't get location, still update firestore, but without location data
        const deactivateLocationError = async (err) => {
            notification.open({
                message: "Unable to Get Your Location",
                description: "You may have disabled location services on this device",
                icon: <MdLocationOff />,
                duration: 0,
                
            });
            
            console.warn(`ERROR(${err.code}): ${err.message}`);
                logActivity('deactivate');
            
            firestore.collection('users').doc(currentUser.id).set({workingAlone: false, 
                reminder_count: null,
                expired_count: null,
            },
            {merge: true})
            .then(() => { setDeactivating(false);})
            .catch((error) => {console.log('Error updating deactivated user',error)});
        }

        if (Capacitor.getPlatform() === 'web') {
            //web code
            navigator.geolocation.getCurrentPosition(deactivateLocationSuccess, deactivateLocationError, {timeout: 10000, maximumAge: 0, enableHighAccuracy:true});
        } else {
            //native code
            if (nativeLocationEnabled) {
                
                //get current position for log
                const deactivateLocation = await getCurrentPosition();
                //stop background tracking
                stopTracking();
                logActivity('deactivate',deactivateLocation);
            } else {
                logActivity('deactivate');
            }
        }

        firestore.collection('users').doc(currentUser.id).set({workingAlone: false, 
            reminder_count: null,
            expired_count: null,
        },
        {merge: true})
        .then(() => { setDeactivating(false);})
        .catch((error) => {console.log('Error updating deactivated user',error)});

        

        /* if (!currentUser.emergencyBeacon) {
            stopWatchingPosition(watchId);
        }  */

        console.log("running clear queue");
        const clearResponse = await callClearPendingUserNotifications({user_id: currentUser.id});
        console.log("Clear Pending Response:",clearResponse);
        //setCurrentUser(p => ({...p, workingAlone:false}));
        
    }

    const timeExpired = () => {
        setTimeExceeded(true);
    }

    const toggleEmergency = async () => {
        setEmergencyActive(p => !p);
        setCurrentUser(p => ({...p, emergencyBeacon:!emergencyActive}))
        
        //schedule emergency notification
        let messageData = {
            notificationType: 'emergency',
            workalone_user_id: currentUser.id,
            workalone_user_first_name: currentUser.first_name,
            workalone_user_last_name: currentUser.last_name,
            group_id: currentUser.group_id,
            body: `${currentUser.first_name} ${currentUser.last_name} activated their emergency beacon!`, 
            title: 'Work Alone EMERGENCY'
        }

        if (!emergencyActive) {
            //user is activating beacon
            console.log("Activating Emergency Beacon");
            if (Capacitor.getPlatform() !== 'web') {
                //native code
                if (nativeLocationEnabled) {
                    setShouldLogActivity('emergencyActivate');
                    startTracking("high");
                } else {
                    notification.open({
                        message: "Unable to Get Your Location",
                        description: "Your emergency alert will still be sent without location. You may have disabled location services on this device",
                        icon: <MdLocationOff />,
                        duration: 0,
                      
                    });
                    logActivity('emergencyActivate');
                }
                await firestore.collection('users').doc(currentUser.id).set({emergencyBeacon: true, emergencyBeaconActivatedTime: serverTimestamp()},{merge: true});
                
                //logActivity('emergencyActivate');
                try {
                    const response = await Axios.post(`https://us-central1-${projectId}.cloudfunctions.net/sendNotificationsFromScheduler`,messageData);
                } catch(error) {
                    console.log("sending Emergency Message error");
                }
            } else {

                //web code
                const emergencyLocationSuccess = async (pos) => {
                    const lastLocation = {lat: pos.coords.latitude, lng: pos.coords.longitude};
                    console.log("Emergency Beacon Location", lastLocation);
                    
                    await firestore.collection('users').doc(currentUser.id).set({emergencyBeacon: true, emergencyBeaconActivatedTime: serverTimestamp(), lastLocation, lastLocationUpdate: serverTimestamp()},{merge: true});
                    logActivity('emergencyActivate',lastLocation);
                    try {
                        const response = await Axios.post(`https://us-central1-${projectId}.cloudfunctions.net/sendNotificationsFromScheduler`,messageData);
                    } catch(error) {
                        console.log("sending Emergency Message error");
                    }
                
                }
                const emergencyLocationError = async (err) => {
                    console.warn(`ERROR(${err.code}): ${err.message}`);
                    notification.open({
                        message: "Unable to Get Your Location",
                        description: "Your emergency alert will still be sent without location. You may have disabled location services on this device",
                        icon: <MdLocationOff />,
                        duration: 0,
                      
                    });
                    await firestore.collection('users').doc(currentUser.id).set({emergencyBeacon: true, emergencyBeaconActivatedTime: serverTimestamp()},{merge: true});
                    logActivity('emergencyActivate');
                    try {
                        const response = await Axios.post(`https://us-central1-${projectId}.cloudfunctions.net/sendNotificationsFromScheduler`,messageData);
                    } catch(error) {
                        console.log("sending Emergency Message error");
                    }
                }
                navigator.geolocation.getCurrentPosition(emergencyLocationSuccess, emergencyLocationError, {timeout: 10000, maximumAge: 0, enableHighAccuracy:true});
            } //web only code
        
        } else {
            //user is deactivating beacon
            await firestore.collection('users').doc(currentUser.id).set({emergencyBeacon: false }, {merge:true});
            
            if (Capacitor.getPlatform() !== 'web') {
                if (nativeLocationEnabled) {
                    let deactivateLocation = await getCurrentPosition();
                    logActivity('emergencyDeactivate',deactivateLocation);
                    stopTracking();
                } else {
                    logActivity('emergencyDeactivate');
                }
                
            } else {
                //web code
                logActivity('emergencyDeactivate');
            }
            callClearPendingUserEmergencyNotifications({user_id: currentUser.id});
            /* if (!currentUser.workingAlone) {
                stopWatchingPosition(watchId);
            } */
            
        }
        


    }

    const handleCheckinFrequencyClicked = () => {
        if (!currentUser.workingAlone) {
            setShowCheckinModal(true);
        }
    }

    
    return (
        <>
        <CheckinFrequencyForm visible={showCheckinModal} onClose={() => setShowCheckinModal(false)} />
        <Row justify="center" align="middle" style={{height: '60vh'}} >
        <Col  style={{width: 350}}>
            { timeExceeded ? 
                <div className="blink-me" style={{textAlign: 'center', fontSize: 30, marginBottom:20}}>
                    <WarningTwoTone twoToneColor="#E03A3F"/> Time Exceeded!
                </div>
            :
            null
            }
            {/* <div>Lat: {location.lat} Lng: {location.lng}</div>
            <div>Location Changed {locationChangedCounter} times</div> */}
            { checkedIn ? 
                <><div style={{textAlign: 'center'}}>
                    
                    <div style={{fontSize: 30, lineHeight: 1}}>Please Check In Before {deadline.format("h:mm a")}</div>
                    <Countdown title="" value={deadline} format="H:mm:ss" valueStyle={{fontSize: 60}}  onFinish={timeExpired}/>
                </div>
                <Button size="large"  disabled={deactivating} type="primary" onClick={startTimer}     style={{width:'100%', height: 50, marginBottom: 40}}><GoLocation style={{marginRight:5,}} />  Check In</Button>
                <Button size="large"  loading={deactivating} onClick={stopTimer}     style={{width:'100%', height: 50}}>{deactivating ? "Deactivating" : "Deactivate"}</Button>
                </>
                
            : 
            <Button size="large" type="primary"  onClick={() => confirmBeforeStart(startTimer)}     style={{width:'100%', height: 50}}> <GoLocation style={{marginRight:5,}} /> Begin Working Alone</Button>
            }
            <div onClick={handleCheckinFrequencyClicked} 
                 style={{width:'100%', textAlign: 'center', marginTop:10, cursor:'pointer'}}
            >
                <IoIosPulse /> Every {currentUser.checkin_frequency} Minutes
            </div>
            {/* <div style={{width:'100%', textAlign: 'center', marginTop:10}}>V 1.0</div> */}

            

        </Col>
        </Row>

        <Row type="flex" justify="center" align="bottom" style={{height: '20vh'}}>
            <Col>
            <div style={{textAlign:'center'}} >
            <label>
                
                <Switch 
                    onChange={() => confirmBeforeStart(toggleEmergency)} 
                    checked={emergencyActive}
                    uncheckedIcon={
                                    <div
                                        style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "left",
                                        height: "100%",
                                        fontSize: 15,
                                        marginLeft:-225,
                                        paddingRight: 2
                                        }}
                                    >
                                    <GoLocation style={{marginRight:5,}} />Emergency&nbsp;Beacon
                                    </div>
                                    }
                    checkedIcon={
                        <div className="blink-me"
                                        style={{
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "left",
                                        height: "100%",
                                        fontSize: 15,
                                        marginLeft:150,
                                        paddingRight: 2
                                        }}
                                    >
                                    <span style={{fontSize:35}}><AlertFilled twoToneColor="#E03A3F" style={{marginRight:10, marginBottom:10}}/></span>Emergency&nbsp;Beacon&nbsp;Active
                                    </div>
                                }
                    height={60}
                    width={350}
                    onColor="#E03A3F"
                    onHandleColor="#FFF"
                    activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"   
                 />
            </label>
            </div>
            </Col>
        </Row>
        </>
        
    )
}

export default Checkin;


import React, {useState, useEffect, memo} from 'react'
import {Statistic} from 'antd'
import {serverTimestamp, fb} from '../firebase'
import moment from 'moment';



const AntCountdown = Statistic.Countdown;
var serverTimeOffset = false;

function getServerTime(callback) {
    if (serverTimeOffset === false) {

        var scripts = document.getElementsByTagName("script"),
            URL = scripts[scripts.length - 1].src;

        var clientTimestamp = Date.parse(new Date().toUTCString());
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("HEAD", URL + "?noCache=" + Date.now(), true);
        xmlhttp.onload = function(){
            if (xmlhttp.readyState === 4) {
                if (xmlhttp.status === 200) {

                    var serverDateStr = xmlhttp.getResponseHeader('Date');
                    var serverTimestamp = Date.parse(new Date(Date.parse(serverDateStr)).toUTCString());

                    var serverClientRequestDiffTime = serverTimestamp - clientTimestamp;
                    var nowTimeStamp  = Date.parse(new Date().toUTCString());

                    var serverClientResponseDiffTime = nowTimeStamp - serverTimestamp;
                    var responseTime = (serverClientRequestDiffTime - nowTimeStamp + clientTimestamp - serverClientResponseDiffTime ) / 2;

                    serverTimeOffset = (serverClientResponseDiffTime - responseTime) / 2;
                    console.log("serverTimeOffset",serverTimeOffset);

                    var date = new Date();

                    date.setTime(date.getTime() + serverTimeOffset);

                    callback.call(null, date, serverTimeOffset);
                } else {
                    console.error(xmlhttp.statusText);
                }
            }
        };
        xmlhttp.send(null);
        
    } else {
        var date = new Date();

        date.setTime(date.getTime() + serverTimeOffset);

        callback.call(null, date, serverTimeOffset);
    }
}


function Countdown(props) {
    const deadline = props.value;
    const [adjustedDeadline,setAdjustedDeadline] = useState(null);

    useEffect(() => {
        if (deadline) {
        
            getServerTime((date, offset) => {

                
                const adjustedTime = deadline.clone().add(offset, 'milliseconds');
                
                
                setAdjustedDeadline(adjustedTime);


                //console.log("deadline", deadlineInMS,"localTimeNow",localTimeNowInMS,"serverTimeNow", serverTimeNowInMS, "offset", offset);
               
                //console.log("Server date", date);
                //console.log("Local Date", new Date());
            
            });
    
       
        
        
            
    
            
        }
    },[deadline]);
    
    
    
        
    return (
        <AntCountdown {...props} value={adjustedDeadline} />
    )
}

export default memo(Countdown)

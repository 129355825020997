import React, {useEffect} from 'react'
import { Layout} from 'antd'
import { firestore } from '../firebase'

const renameDepartmentsToGroups = async () => {
    let departmentRefs = await firestore.collection("departments").get();
    departmentRefs.forEach(department => {
        firestore.collection("groups").doc(department.id).set(department.data());
    })
}

const renameDeparmentToGroupsUsers = async () => {
    let userRefs = await firestore.collection("users").get();
    userRefs.forEach(user => {
        let updatedUser = user.data();
        updatedUser.group = user.data().department;
        updatedUser.group_id = user.data().department_id;
        updatedUser.group_name = user.data().department_name;
        delete updatedUser.department;
        delete updatedUser.department_id;
        delete updatedUser.department_name;
        console.log("Updated User",updatedUser);
        firestore.collection("users").doc(user.id).set({...updatedUser});;
    })
}

const deleteTestUserActivityLogs = async () => {
    
    //Test User
    let activityLogRefs = await firestore.collection('activityLog').where('user_id','==','RnLvzL0EU8UMXtxAJLJ1Tkn609r2').get();
    
    //John Doe
    //let activityLogRefs = await firestore.collection('activityLog').where('user_id','==','FX7JmX4DMTYbYlMqhSUUscFwZGr1').get();
    
    //Louis Plett recent logs
    //let activityLogRefs = await firestore.collection('activityLog').where('user_id','==','OD09hqmD8ScqMMTfVSLBl6YjQQ12').orderBy('time','desc').limit(26).get();
    console.log("Activity Logs Found:", activityLogRefs.docs.length);
    activityLogRefs.forEach(log => {
        firestore.collection('activityLog').doc(log.id).delete();
    })
}




function RunFireStoreUpdate() {
    const {Content} = Layout;
    //const [results, setResults] = useState([]);

    const updateToRun = async () => {
        
        //renameDepartmentsToGroups();
        //renameDeparmentToGroupsUsers();
        //deleteTestUserActivityLogs();
       
       
    }

    useEffect(() => {
        updateToRun();
      },[])




    return (
        <div>
      
        
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          
        </Content>
            
        </div>
    )
}

export default RunFireStoreUpdate

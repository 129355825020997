import React, {useEffect, useState, useRef} from 'react'
import {Breadcrumb, Layout, PageHeader, Button, Table, Input, Row, Col, Space,  Select, Tag, Modal, Grid, Card, List, message} from 'antd'
import { ReloadOutlined, LeftOutlined, RightOutlined, SafetyOutlined, FieldTimeOutlined, AlertOutlined, StopOutlined, PlayCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { IoIosPulse } from "react-icons/io";
import { GoLocation } from "react-icons/go";
import {useHistory} from 'react-router-dom'
import { firestore} from '../firebase'
import {debounce} from '../shared/utilities'
import moment from 'moment'
import GoogleMap from '../shared/GoogleMap'
import UserSelect from '../shared/UserSelect'
import {firestoreReadCount, userState} from '../atoms'
import {useRecoilState} from 'recoil'




const { useBreakpoint } = Grid;




function ActivityLog() {
  
  
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  const screens = useBreakpoint();
  //const resultsPerPage = 20;
    
  //state
  const [ data, setData] = useState([]);
  const [ loading, setLoading] = useState(false);
  const [ searchText, setSearchText] = useState('');
  const [ tableParams, setTableParams] = useState({});
  const [ lastDocRef, setLastDocRef] = useState(null);
  const [ firstDocRef, setFirstDocRef] = useState(null);
  const [ prevDisabled, setPrevDisabled] = useState(true);
  const [ nextDisabled, setNextDisabled] = useState(false);
  const [ currentPage, setCurrentPage] = useState(1);
  const [marker, setMarker] = useState({lat:49.5250615, lng: -96.6832357, name: '', id: 0});
  const [viewingIndex, setViewingIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Location');
  const [userFilter, setUserFilter] = useState(undefined);
  const [readCount, setReadCount] = useRecoilState(firestoreReadCount);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  
 
  const [resultsPerPage, setResultsPerPage] = useState(20);

  let unsubscribeFromActivityLog = null;

  const fetch = async (params = {}, searchTextToUse = null, movePage = null) => {
      
    if (searchTextToUse) {
      searchTextToUse = searchTextToUse.toLowerCase();
    }

    

    //console.log("fetch params",params);
    
    setTableParams(params);
    let sortField = params.sortField ? params.sortField : "time";
    let sortOrder = 'desc';
    
    setLoading(true);
    
    try {
      let refs = null;
      let startAfter = '';
      //let limit = 10;
      if (movePage === "next") {
        startAfter = lastDocRef;
      }

      let query = firestore.collection("activityLog").orderBy(sortField, sortOrder).limit(resultsPerPage);

      if (currentUser.role !== 'admin') {
        query = query.where('team_id','==',currentUser.team_id)
      }

      if (searchTextToUse) {
        query = query.where('search_fields', 'array-contains', searchTextToUse);
      }

      if (params.filter) {
        query = query.where('user_id','==',params.filter);
      }

      
      if (movePage === "next" || !movePage) {
      //Moving to next page or getting first page
        if (startAfter) {
          query = query.startAfter(startAfter);
        }
      } else {
      //Moving to Previous Page
        query = query.endBefore(firstDocRef).limitToLast(resultsPerPage)
      }
      //refs = await query.get();

      unsubscribeFromActivityLog = query.onSnapshot((refs) => {
        setReadCount(p => p+refs.docs.length);
        if (refs.docs[refs.docs.length -1] ) {
            setLastDocRef(refs.docs[refs.docs.length -1]);
          } 
          
          if (refs.docs[0]) {
            setFirstDocRef(refs.docs[0]);
          } else {
            //no results on this page, set First Doc to Last Doc of previous page for moving backwards at the end
            setFirstDocRef(lastDocRef);
          }

          let results = [];
         
          refs.forEach(function(doc) {
            results.push({id: doc.id, ...doc.data()})
          });
            
          setData(results);
    
          let page = currentPage;
          if (params.page) {
            page = params.page;
            //console.log("does this ever run???");
          }
          
          if (results.length < resultsPerPage) {
            setNextDisabled(true);
          } else {
            setNextDisabled(false);
          }
    
          
          if (movePage === 'next') {
            page = currentPage+1;
          }
          /* console.log("page",page,"resultsPerPage",resultsPerPage, "totalequipment",totalEquipment);
          if (page*resultsPerPage === totalEquipment) {
            console.log("disabling next");
            setNextDisabled(true);
          } */
    
          if (movePage === 'next') {
            setPrevDisabled(false);
            setCurrentPage(prevCurrentPage => prevCurrentPage + 1 );
          }
    
          if (movePage === 'previous') {
            if (currentPage === 2) {
              setPrevDisabled(true);
            }
            setNextDisabled(false);
            setCurrentPage(prevCurrentPage => prevCurrentPage - 1 );
          }
         
          setLoading(false);
      })
      
      

    } catch(error) {
      console.log("Error getting documents: ", error);
    }
  };

  

/*   useEffect(() => {
      return () => {
          console.log("unsubscribing activity log");
          unsubscribeFromActivityLog();
      }
  },[]) */

  
  useEffect(() => {
    console.log("useEffect for resultsPerPage change",resultsPerPage);
    let params = tableParams;
    params.page = 1;
    setCurrentPage(1);
    setPrevDisabled(true);
    fetch(params, searchText);
    return () => {
      console.log("unsubscribing activity log");
      unsubscribeFromActivityLog();
  }
  },[resultsPerPage])

  const activityTag = (type) => {
    switch (type) {
      case 'emergencyOverride':
        return (<Tag color='error'><CloseSquareOutlined /> Stop Emergency </Tag>)
      case 'emergencyActivate':
        return (<Tag color='error'><AlertOutlined /> Emergency Beacon</Tag>)
      case 'emergencyDeactivate':
        return (<Tag color='green'><SafetyOutlined /> Deactivate Emergency</Tag>)
      case 'override':
        return (<Tag color='error'><CloseSquareOutlined /> Stop Timer</Tag>)
      case 'checkin':
          return (<Tag color='warning'><PlayCircleOutlined /> Check In</Tag>)
      case 'refresh':
          return (<Tag><ReloadOutlined /> Reset Timer</Tag>)
      case 'deactivate':
        return (<Tag color='success'><SafetyOutlined /> Deactivate Timer</Tag>)
    }
  }

  const logInfo = (record) => {
    if (record.type === 'override' || record.type === 'emergencyOverride') { 
      return `Override by ${record.override_user_first_name} ${record.override_user_last_name}`
    }
    if (record.deadline) {
        return moment(record.deadline.toDate()).format("MMM DD/YY h:mm a")
    } else {
        return null;
    }
  }

  const logInfoCard = (record) => {
    if (record.type === 'override' || record.type === 'emergencyOverride') { 
      return `Override by ${record.override_user_first_name} ${record.override_user_last_name}`
    } else {
      return null;
    }
    
  }


  const showMap = (record, index) => {
    console.log("showMap",index);
    if (record.location) {
      setMarker({id: record.id,
        lat: record.location.lat,
        lng: record.location.lng, 
        name: `${record.first_name} ${record.last_name}`,
        logEntry: record });
      let title = (<div>  {activityTag(record.type)} {moment(record.time.toDate()).format("MMM DD/YY h:mm a")}</div>);
      //title += ` ${record.first_name} ${record.last_name} `;
      setModalTitle(title);
      setViewingIndex(index);
      setShowModal(true);
    }
    
  }

  const showNextLocation = () => {
    let nextIndexWithLocation = viewingIndex - 1;
    let entryHasALocation = false;
    
    while (entryHasALocation === false && nextIndexWithLocation >= 0) {
      if (data[nextIndexWithLocation].location) {
        entryHasALocation = true;
        showMap(data[nextIndexWithLocation], nextIndexWithLocation);
      } else {
        nextIndexWithLocation = nextIndexWithLocation - 1;
      }
    }

    if (!entryHasALocation) {
      console.log("No More Entries");
      message.warn("No more Entries on this page");
    }
  }

  const showPreviousLocation = () => {
    let prevIndexWithLocation = viewingIndex + 1;
    let entryHasALocation = false;
    
    while (entryHasALocation === false && prevIndexWithLocation < data.length) {
      if (data[prevIndexWithLocation].location) {
        entryHasALocation = true;
        showMap(data[prevIndexWithLocation], prevIndexWithLocation);
      } else {
        prevIndexWithLocation = prevIndexWithLocation + 1;
      }
    }

    if (!entryHasALocation) {
      console.log("No More Entries");
      message.warn("No more Entries on this page");
    }
  }

  const columns = [
   
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text, record) => activityTag(record.type),
      width: '20%',
    },
    {
      title: 'Name',
      render: (text, record) => `${record.last_name}, ${record.first_name}`,
      width: '20%',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (text, record) => moment(record.time?.toDate()).format("MMM DD/YY h:mm a"),
      width: '20%',
      
      className: "equipment-cell"
    },
    
    {
      title: 'Duration (minutes)',
      dataIndex: 'checkin_frequency',
      width: '10%',
      
    },
    {
        title: 'Checkin Deadline',
        dataIndex: 'deadline',
        render: (text, record) => {
              logInfo(record);
            },
        width: '20%',
        
        
      },
      {
        title: 'Location',
        render: (text,record, index) => {
            if (record.location) {
                return <a href='#' onClick={() => showMap(record, index)}>View</a>
            } else {
                return null;
            }
            
        }
      }
    
   
  ];

    
    
    const cancel = (e) => {
      console.log(e);
      
    }

    const handleTableChange = (pag, filters, sorter) => {
      //const pager = { ...pagination };
      //pager.current = pag.current;
      setCurrentPage(1);
      setPrevDisabled(true);
      //setPagination({...pager},
        fetch({
         /*  results: pag.pageSize,
          page: pag.current, */
          page: 1,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
          
        },searchText)
        
      //  );
      
      
      
    };

    const onTextSearch = debounce((value) => {
           //console.log("onKeystroke",value);
           setCurrentPage(1);
           setPrevDisabled(true);
           setSearchText(value, setCurrentPage(1,fetch(tableParams,value)) );
           //fetch(tableParams,value);
    },200)

    const setFilter = (value) => {
      console.log("user filter:",value);
      setUserFilter(value);
      fetch({filter: value});
    }

    let modalWidth = '80%'
    if (screens.xs) {
      modalWidth = '100%'
    }

    //console.log("screens",screens);

    let displayData;
    if (screens.lg) {
      displayData = (
        <Table
              columns={columns}
              className="ant-table"
              //scroll={{x: 'calc(100vh - 4em)'}}
              scroll={{ x: 'max-content' }}
              rowKey={record => record.id}
              sortDirections={['ascend']}
              dataSource={data}
              pagination={false}
              loading={loading}
              onChange={handleTableChange}
              size="small"
              
            /> 
      )
    } else {
      displayData = (
        <List
              grid={{ gutter: 16, column: 1 }}
              dataSource={data}
              renderItem={(entry, index) => (
                <List.Item>
                  <div onClick={() => showMap(entry, index)} >
                  <Card
                    hoverable='true'
                    bodyStyle={{padding:20}}
                  >
                  <Row>
                    <Col span={12}>
                      {activityTag(entry.type)}
                    </Col>
                    <Col span={12}style={{textAlign:'right'}}>
                      {`${entry.last_name}, ${entry.first_name}`}
                    </Col>
                  </Row>
                  <Row  align="middle">
                    <Col span={12}>
                      {moment(entry.time.toDate()).format("MMM DD/YY h:mm a")}
                    </Col>
                    { (entry.checkin_frequency || entry.location)  && 
                    <Col span={12}style={{textAlign:'right'}}>
                      {entry.checkin_frequency && <Tag style={{marginTop:5, marginRight: 0}}><IoIosPulse /> Every {entry.checkin_frequency} Mins</Tag>}
                      {entry.location && <Tag style={{marginTop:5, marginRight: 0}}><GoLocation /></Tag> }
                    </Col>
                    }
                  </Row>
                  <Row>
                    <Col>
                      {logInfoCard(entry)}
                    </Col>
                  </Row>
                   </Card>
                  </div>
                </List.Item>
              )}
            />
      
      )
    }


    
    

    return (
        <div >
          <Breadcrumb className="no-print">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Activity Log</Breadcrumb.Item>
            
          </Breadcrumb>
       
        
        <Content className="site-layout-background">
          
       
            <PageHeader
            className="site-page-header-responsive"
            avatar={{icon: <FieldTimeOutlined />}}
            ghost={false}
            onBack={() => window.history.back()}
            title="Activity Log"
            
            backIcon={false} 
            extra={[
              <Row gutter={[8,8]} key="extra_row">
                <Col lg={7} sm={24}>
                <UserSelect style={{width:150}} placeholder="Filter User" onChange={setFilter} allowClear />
                </Col>
               
               
              </Row>
            ]}
           
            >
            
            </PageHeader>

            {displayData}

            
            <Row gutter={[8,8]} align="middle" justify="end" style={{marginTop: '16px'}} >
              
              <Col >
                <Space className="no-print">
                  <Button disabled={prevDisabled} key="previous" type="default" onClick={() => fetch(tableParams, searchText, "previous")}>
                      <LeftOutlined />Previous
                    </Button>
                  
                  <Button disabled={nextDisabled} key="next" type="default" onClick={() => fetch(tableParams, searchText, "next")}>
                      <RightOutlined />Next
                  </Button>
                  <Select style={{width: 110}} defaultValue={resultsPerPage} onChange={(value) => setResultsPerPage(value)}>
                    <Select.Option value={20}>20 / page</Select.Option>
                    <Select.Option value={50}>50 / page</Select.Option>
                    <Select.Option value={100}>100 / page</Select.Option>
                  </Select>
                </Space>
              </Col> 
                
                
              
            </Row>

        </Content>

        <Modal visible={showModal} onCancel={() => setShowModal(false)} width={modalWidth} title={modalTitle} footer={null}>
        
          <div style={{width: '90hw', height: '70vh'}} >
            <Row style={{position: 'absolute', top:90, left: 30, zIndex: 99999}}>
            <Space>
              <Button  key="prevLocation" type="default" onClick={showPreviousLocation}>
                <LeftOutlined />Prev Log
              </Button>
              <Button  key="nextLocation" type="default" onClick={showNextLocation}>
                <RightOutlined />Next Log
              </Button>
            </Space>
          </Row>
            <GoogleMap users={[marker]} center={{lat: marker.lat, lng: marker.lng}} />
          </div>
        </Modal>
            
        </div>
    )
}

export default ActivityLog

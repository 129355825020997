import { atom } from 'recoil';

export const userState = atom({
    key: 'userState',
    default: null
});

export const firestoreReadCount = atom({
    key: 'readCount',
    default: 0
});

export const locationState = atom({
    key: 'locationState',
    default: null
})

export const nativeLocationEnabledState = atom({
    key: 'nativeLocationEnabledState',
    default: true
})

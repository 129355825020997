import React, {useState} from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import logo from '../assets/loginLogo.png'
import {  auth } from '../firebase'



const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
};




const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const onFinish = values => {
    const {email, password} = values;
    setLoading(true);
    auth.signInWithEmailAndPassword(email, password).catch(function(error) {
        // Handle Errors here.
        console.log("Sign in Error",error.code,error.message);
        message.error(error.message);
        setLoading(false);
        // ...
      });

  };

  const forgotPassword = () => {
    const emailAddress = form.getFieldValue("email");
    console.log("email:",emailAddress);
    if (emailAddress) {
      auth.sendPasswordResetEmail(emailAddress).then(function() {
        message.success("An password reset email has been sent to " + emailAddress);
       }).catch(function(error) {
         console.log(error.message);
         message.error(error.message);
       });
     
    } else {
      message.error("Please enter your email address and click 'Forgot Password' ");
    }
 }
    

  

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
    
  };

  

  return (
    <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col style={{width: 350}}>
        <img src={logo} style={{margin: 10, width: 330}} />
        <Form
        {...layout}
        form={form}
        name="basic"
        size="large"
        style={{padding: '10px 10px'}}
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        
        >
        <Form.Item
            
            name="email"

            
            
            rules={[
            {
                required: true,
                type: 'email',
                message: 'Please input your email!',
            },
            ]}
        >
            <Input placeholder="Email Address" prefix={<MailOutlined style={{ fontSize: 13 }} />} />
        </Form.Item>

        <Form.Item
            
            name="password"
            
            
            rules={[
            {
                required: true,
                message: 'Please input your password!',
            },
            ]}
        >
            <Input.Password placeholder="Password" prefix={<LockOutlined style={{ fontSize: 13 }} />}/>
        </Form.Item>

        {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

        <Form.Item >
            
            <Button type="primary" loading={loading} htmlType="submit" style={{width: '100%', marginBottom:'10px'}}>
            Login
            </Button>
            <a className="login-form-forgot" href="#" onClick={forgotPassword} >Forgot password? </a>
        </Form.Item>
        </Form>
    </Col>
    </Row>
  );
};

export default Login;
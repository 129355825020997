import React from 'react';
import {  Modal } from 'antd';
import {userState} from '../atoms.js'
import { useRecoilState } from 'recoil';
import CheckinFrequencySelect from './CheckinFrequencySelect.js';
import {firestore} from '../firebase'

const CheckinFrequencyForm = ({ visible, onClose}) => {
   
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    
    const handleCheckinFrequencyChange = async (value) => {
        console.log("value:",value);
        setCurrentUser(p => ({...p, checkin_frequency: value}))
        try {
           await firestore.collection('users').doc(currentUser.id).set({checkin_frequency: value},{merge:true});
        } catch(error) {
            console.log("Error updating checkin frequency in firestore");
        }
        onClose();
    }
    return (
      <Modal
        visible={visible}
        title="Change Check-in Frequency"
        footer={null}
        onCancel={onClose}
        
      >
        <CheckinFrequencySelect defaultValue={currentUser.checkin_frequency} 
                                onChange={(value) => handleCheckinFrequencyChange(value)} 
                                style={{width:'100%'}}
                                size="large" />
      </Modal>
    );
  };

  export default CheckinFrequencyForm;
import React, {useEffect, useState} from 'react';
import GoogleMap from '../shared/GoogleMap';
import {Tag, Row, Col, List,  Space, Grid, Button, Popconfirm, message} from 'antd';
import {firestore, functions, serverTimestamp, environment} from '../firebase'
import './LiveDashboard.css'
import moment from 'moment'
import { IoIosPulse, IoMdLocate } from "react-icons/io";
import { MdMyLocation } from "react-icons/md";
import {WarningTwoTone, PlayCircleOutlined, AlertTwoTone} from '@ant-design/icons';
import { GoLocation } from "react-icons/go";
import Countdown from '../shared/Countdown';
import { useRecoilState } from 'recoil';
import {userState, firestoreReadCount} from '../atoms';

let unsubscribeWorkingAloneUsers = null;
let unsubscribeEmergencyUsers = null;
const { useBreakpoint } = Grid;
const callClearPendingUserNotifications = functions.httpsCallable('clearPendingUserNotifications');
const callClearPendingUserEmergencyNotifications = functions.httpsCallable('clearPendingUserEmergencyNotifications');


const LiveDashboard =() => {
    const [userIsExpired, setUserIsExpired] = useState({});
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const [readCount, setReadCount] = useRecoilState(firestoreReadCount);
    const [mapUsers, setMapUsers] = useState([]);
    const screens = useBreakpoint();
    const [users, setUsers] = useState([]);
    const [emergencyUsers, setEmergencyUsers] = useState([]);
    const [combinedUsers, setCombinedUsers] = useState([]);
    const [now, setNow] = useState(new Date());
    const [mapCenter, setMapCenter] = useState({lat: 49.5250615,lng: -96.6832357});
    const [zoom, setZoom] = useState(13);

    
    useEffect(() => {
        //if (environment === 'dev') {
            const intervalID = setInterval(() => { 
                setNow(new Date());
                //console.log(new Date());
            }
                ,1000);

            return(() => {
                clearInterval(intervalID);
            })
        //}
    },[]);
    
    useEffect(() => {
         let workingAloneUsersQuery = firestore.collection('users').where("workingAlone","==",true).where("emergencyBeacon","==",false).orderBy('deadline')
         if (currentUser.role !== 'admin') {
             workingAloneUsersQuery = workingAloneUsersQuery.where('team_id','==',currentUser.team_id);
         }
         
         unsubscribeWorkingAloneUsers = workingAloneUsersQuery.onSnapshot((refs) => {
            let results = [];
            let userExpired = {};
            setReadCount(p => p + refs.docs.length);
            refs.docs.forEach(ref => {
                let data = ref.data();
                delete data.password;
                delete data.disabled;
                delete data.search_fields;
                delete data.role;
                results.push({...data, id: ref.id});
                if (data.deadline.toDate() < new Date()) {
                    userExpired[ref.id] = true;
                } else {
                    userExpired[ref.id] = false;
                }
            })
                setUsers(results);
                setUserIsExpired(userExpired);
        });
        
        let emergencyUsersQuery = firestore.collection('users').where("emergencyBeacon","==",true).orderBy('emergencyBeaconActivatedTime');
        if (currentUser.role !== 'admin') {
            emergencyUsersQuery = emergencyUsersQuery.where('team_id','==',currentUser.team_id);
        }
        unsubscribeEmergencyUsers = emergencyUsersQuery.onSnapshot((refs) => {
            let results = [];
            setReadCount(p => p + refs.docs.length);
            
            refs.docs.forEach(ref => {
                let data = ref.data();
                delete data.password;
                delete data.disabled;
                delete data.search_fields;
                delete data.role;
                results.push({...data, id: ref.id});
                
            })
                setEmergencyUsers(results);
                
        });

        return (() => {
            unsubscribeWorkingAloneUsers();
            unsubscribeEmergencyUsers();
        });
    },[]);

    //combine 2 listeners into one state
    useEffect(() => {
        const emergencyUserIds = emergencyUsers.map((eu) => eu.id);
        
        
        //remove duplicate ids (happens rarely when user has used emergency beacon then deactivates timer)
        let combinedUsersArray = [...emergencyUsers];
        users.forEach((user) => {
            if (!emergencyUserIds.includes(user.id)) {
                combinedUsersArray.push(user);
            }
        })
        //console.log("combined users",combinedUsersArray);
        setCombinedUsers(combinedUsersArray);
        
        let usersWithLocation = [];
        combinedUsersArray.forEach(user => {
            if (user.lastLocation) {
                usersWithLocation.push(
                    {   id: user.id,
                        lat: user.lastLocation.lat, 
                        lng: user.lastLocation.lng,
                        name: `${user.first_name} ${user.last_name}`}
                )
            }
        })

        setMapUsers(usersWithLocation);
       
    },[users,emergencyUsers])


    const timeExpired = (user_id) => {
        setUserIsExpired(p => ({...p, [user_id]: true}));
        
    }

    const calcUpdatedTimeDifference = (lastLocationUpdate) => {
       const diff = moment(now).diff(lastLocationUpdate.toDate(),'seconds');
       
       if (diff > 120) {
        return ((diff / 60).toFixed(0) + "m ago");
       } else {
        return (diff  + "s ago");
       }

    }

    const deactivate = async (type,user) => {
        try {
            switch (type) {
                case 'override':
                    await firestore.collection('users').doc(user.id).set({workingAlone: false, reminder_count: null, expired_count: null},{merge: true})
                    await callClearPendingUserNotifications({user_id: user.id});
                    break;
                case 'emergencyOverride':
                    await firestore.collection('users').doc(user.id).set({emergencyBeacon: false, emergency_count: null},{merge: true})
                    await callClearPendingUserEmergencyNotifications({user_id: user.id});
                    break;
                

            }
            

            let record = {
                type: type,
                user_id: user.id,
                team_id: user.team_id,
                override_user_id: currentUser.id,
                override_user_first_name: currentUser.first_name,
                override_user_last_name: currentUser.last_name,
                time: serverTimestamp(),
                first_name: user.first_name,
                last_name: user.last_name,
                 
            };

            await firestore.collection('activityLog').add(record);

        } catch(error) {
            console.log("Error deactivating",error);
            message.error("Error deactivating")
        }
        
    }

    const renderListTitle = (user) => {
        let title = <div>{user.first_name} {user.last_name}</div>;
        if (user.emergencyBeacon) {
            title = (<div className="blink-me">
                        <AlertTwoTone twoToneColor="#E03A3F"/> {user.first_name} {user.last_name} EMERGENCY
                       </div>);
        
        }
        if (userIsExpired[user.id]) {
           title = (<div className="blink-me">
                   <WarningTwoTone twoToneColor="#E03A3F"/> {user.first_name} {user.last_name} Time Exceeded!
                    </div>
                  )
        } 

        const listTitle =   (
                        <>
                        <Row align="middle">
                            <Col span={24}>
                                {title}
                            </Col>
                            
                        </Row>
                        
                        </>
                    )
        
    return listTitle;
    
    }
        
    let height = '70vh';
    if (screens.lg) {
        height = '90vh';
    }

    //console.log("screens",screens);
    
    return (
        <Row  >
            <Col xs={24} sm={24} md={24} lg={17}  >
                <div style={{ height: height, width: '100%', padding: 20 }}>
                    <GoogleMap users={mapUsers} center={mapCenter} setCenter={setMapCenter} zoom={zoom} setZoom={setZoom} />
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={7} style={{padding:'20px', }}>
                <List
                    
                    size="medium"
                    header={<div>Employees Working Alone</div>}
                    bordered
                    dataSource={combinedUsers}
                    renderItem={user => (
                        <List.Item
                            key={user.id + user.emergencyBeacon + user.workingAlone}
                        >
                            <List.Item.Meta
                                title={renderListTitle(user)}
                                description={<div style={{border: '1px solid #f0f0f0', padding: 10}}>
                                            <Row style={{marginBottom:5}}>
                                                <Col span={24}>
                                                    <Button icon={<IoMdLocate style={{marginRight:5, }} />} block="true"  onClick={() => {setMapCenter({...user.lastLocation, date: new Date()}); setZoom(18)}}>Focus</Button>
                                                </Col>
                                            </Row>
                                            {user.emergencyBeacon ? 
                                                <>
                                                    <Tag style={{marginBottom:5}}>Activated {moment(user.emergencyBeaconActivatedTime.toDate()).format("MMM Do h:mm a")} </Tag>
                                                    {user.lastLocationUpdate? 
                                                        <Tag style={{marginBottom:5}}><GoLocation /> Updated {moment(user.lastLocationUpdate.toDate()).format("MMM Do h:mm a")} </Tag>
                                                    :
                                                        null
                                                    }
                                                    <Popconfirm
                                                        title={`Deactivate Emergency for ${user.first_name} ${user.last_name}?`}
                                                        onConfirm={() => deactivate('emergencyOverride',user)}
                                                        //onCancel={cancel}
                                                        okText="Yes"
                                                        cancelText="No">
                                                        <Button block type="primary">Deactivate</Button>
                                                    </Popconfirm>
                                                </>
                                            :
                                            <>
                                                <Tag><PlayCircleOutlined /> {moment(user.lastCheckinTime.toDate()).format("MMM Do h:mm a")} </Tag>
                                                {user.lastLocationUpdate ? 
                                                        <Tag style={{marginTop:5}}><GoLocation /> Updated {calcUpdatedTimeDifference(user.lastLocationUpdate)} </Tag>
                                                    :
                                                        null
                                                    }
                                                <Tag style={{marginTop:5}}><IoIosPulse /> Every {user.checkin_frequency} Mins</Tag>
                                                <Space style={{marginTop:5}}><div>Checkin Deadline:</div> <Countdown value={moment(user.deadline.toDate())} format="H:mm:ss" valueStyle={{fontSize: 15}} onFinish={() => timeExpired(user.id)} /></Space>
                                                <Popconfirm
                                                    title={`Stop timer for ${user.first_name} ${user.last_name}?`}
                                                    onConfirm={() => deactivate('override',user)}
                                                    //onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No">
                                                    <Button block type="primary" style={{marginTop: 5}}>Deactivate</Button>
                                                </Popconfirm>
                                            </>
                                            }
                                            </div>
                                            
                                        
                                            }
                            />
                        </List.Item>
                    )}
                />
      </Col>
      </Row>
    )
}

export default LiveDashboard

import React, {useEffect, useState} from 'react'
import { Form, Input, Button, Breadcrumb, Layout, PageHeader, Row, Col, message,  Select, Checkbox, Table, Popover} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons';
import { firestore, functions } from '../../firebase'
import {convertToSearchFields} from '../../shared/utilities'
import GroupSelect from '../../shared/GroupSelect'
import TeamSelect from '../../shared/TeamSelect'
import CheckinFrequencySelect from '../../shared/CheckinFrequencySelect';
import { useRecoilState } from 'recoil';
import { userState } from '../../atoms';







const createUser = functions.httpsCallable('createUser');
const updateUser = functions.httpsCallable('updateUser');


function UserForm() {
    
    const history = useHistory();
    const [form] = Form.useForm();
    const { id } = useParams();
    const location = useLocation();
    const [loading,setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const [title, setTitle] = useState('New User');
    const [originalData, setOriginalData] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const {Option} = Select;
    
    

 
    const {Content} = Layout;
    const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };

    const tailLayout = {wrapperCol: { offset: 8, span: 16}}
    
      useEffect(() => {
        //Get document from Firebase if id exists as a param
        console.log("id",id);
        
        
        if (id) {
          //if (location.state) {


            console.log("getting data from location.state");
            setTitle(location.state.first_name);
            const data = location.state;
            delete data.id;
            setOriginalData(data);
            
            form.setFieldsValue(data);
         
         
            /* }  else {
            console.log("Fetching from Firestore");
            firestore.collection("users").doc(id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                form.setFieldsValue(data);
                setTitle(data.first_name);
              } else {
                console.log("No such document!");
                message.error("Could not load Part. It does not exist!");
              }
            }).catch(function(error) {
                console.log("Error getting document:", error);
                message.error("Error loading Part");
            });
          } */

          
       
        } //if (id)

        

        
      },[id,form,location.state])

     
           
      const onFinish = async values => {
        console.log('Values at beginning of onFinish:', values);
        values.search_fields = convertToSearchFields([values.first_name, values.last_name]);
        values.group_id = values.group.value;
        values.group_name = values.group.label;
        


        if (currentUser.role !== 'admin') {
          const team_id =  currentUser.team_id;
          const teamRef = await firestore.collection('teams').doc(team_id).get();
          const team_name = teamRef.data().name;
          values.team_id = team_id;
          values.team_name = team_name;
          values.team = {
            key: team_id,
            label: team_name,
            value: team_id
          }
        } else {
          values.team_id = values.team.value;
          values.team_name = values.team.label;
        }


        
       
        
        console.log("Values before posting to Firebase",values)
        //values contains form data to be submitted
        
        sendToFirebase(values);
      };
    
      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      const sendToFirebase = (values) => {
        //console.log("Values before posting to Firebase",values)
        setLoading(true);
        
        if (id) {
         values.id = id;
         if (originalData.first_name !== values.first_name ||
             originalData.last_name !== values.last_name ||
             (values.password !== "" && values.password != null) ||
             originalData.email !== values.email ||
             originalData.disabled !== values.disabled ||
             originalData.role !== values.role ||
             originalData.team_id !== values.team_id
             ) {

              //need to update authentication as well as firestore
              console.log("updating authentication");
              updateUser(values)
              .then(function(resp) {
                //Display success
                console.log(resp.data.result);
                setLoading(false);
                message.success(`Updated User: ${values.first_name + " " + values.last_name}`);
                history.push('/users');
              })
              .catch(function(error) {
                
                console.log(error.message);
                message.error(error.message);
                setLoading(false);
                //Display error
              });
              
         
            } else {
              //update only firestore
              console.log("updating only firestore");
              firestore.collection('users').doc(id).set({...values},{merge:true})
                .then(() => { 
                  message.success(`Updated User: ${values.first_name + " " + values.last_name}`);
                  setLoading(false);
                  history.push('/users');
                })
                .catch((error) => { console.log("Error updating user",error)})

                
            }
        } else {
            //need this field initialized to false for liveDashboard query to work
            values.emergencyBeacon = false;
            createUser(values)
            .then(function(resp) {
              //Display success
              console.log(resp.data.result);
              message.success(`Created User: ${values.first_name + " " + values.last_name}`);
              history.push('/users');
            })
            .catch(function(error) {
              const code = error.code;
              const message = error.message;
              console.log(error.code,error.message);
              message.error(error.message);
              //Display error
            });
          
          
        }

      }

      
      const onValuesChange = () => {
        console.log("OnValuesChange triggered");
        setFormChanged(true);
      }

      
      let saveButton = null;
      if (formChanged) {
        saveButton = <Button key="save" type="primary" loading={loading} onClick={() => form.submit()} style={{minWidth: '125px'}}><SaveOutlined />Save</Button>;
      }

      let passwordPlaceholder = null;
      let passwordRequired = true;
      if (id) {
        passwordPlaceholder = "leave blank for no change";
        passwordRequired = false;
      }

      const content = (
        <div>
          <table style={{padding:20}}>
            <tr >
              <th style={{verticalAlign:"top"}}>
                High Accuracy:
              </th>
              <td style={{padding:20, paddingTop:0, verticalAlign:"top"}}>
              Updates location every 20 metres. <br/> Never turns off tracking during entire working alone session
              </td>
            </tr>
            <tr>
            <th style={{verticalAlign:"top"}}>
                Battery Saving:
              </th>
              <td style={{padding:20, paddingTop:0, verticalAlign:"top"}}>
                Updates location every 50 metres.<br/> Automatically starts and stops tracking based on user movement<br/>
                <i>*on iOS tracking may not activate until 200m of movement, if user stops moving for 5 minutes</i><br/>
                <i>*on iOS requires "Always" location permission in order to start/stop tracking based on motion</i>
              </td>
            </tr>
          </table>
         
        </div>
      )
      
    return (
        <div>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Users</Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        
        <Content className="site-layout-background">
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={'User: ' + title}
                extra={[
                    saveButton,
                    <Button key="close" type="default" onClick={() => window.history.back()} style={{minWidth: '125px'}}> 
                        <CloseOutlined />Close
                    </Button>,
                ]}
                
                >
                
                </PageHeader>
            
            <Form
            {...layout}
            form={form}
            name="basic"
            size="large"
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            
            initialValues={{ 
              first_name: null,
              last_name: null,
              display_name: null,
              email: null,
              password: null,
              disabled: false,
              role: "user",
              checkin_frequency: '60',
              group: {},
              tracking_accuracy: 'high'
            }}
            >
                <Row gutter={[16,16]}>
                    <Col sm={24} lg={12} >
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            className=""
                            rules={[{required: true, message: 'Please input a first name'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            className=""
                            rules={[{required: true, message: 'Please input a last name'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            className=""
                            rules={[{required: true, type: 'email', message: 'Please input a valid email address'}]}
                            
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            
                            rules={[
                            {
                                required: passwordRequired,
                                min: 6,
                                message: 'Please input a valid password! Min 6 characters',
                            },
                            ]}
                        >
                            <Input.Password placeholder={passwordPlaceholder} />
                        </Form.Item>

                        <Form.Item className="" label="Role" name="role">
                          <Select placeholder="Select Role">
                            <Option value="user">user</Option>
                            <Option value="manager">manager</Option>
                            { currentUser.role === 'admin' ? <Option value="admin">admin</Option> : null }
                          </Select>
                        </Form.Item>
                       
                        <Form.Item label="Check-in Frequency" name="checkin_frequency">
                          <CheckinFrequencySelect />
                        </Form.Item>
                        <Form.Item label="Tracking Accuracy">
                          <Form.Item label="Tracking Accuracy" name="tracking_accuracy" noStyle={true}>
                            <Select style={{ width: 160 }}>
                              <Option value="high">High Accuracy</Option>
                              <Option value="medium">Battery Saving</Option>
                            </Select>
                          </Form.Item>
                          <Popover content={content} title="Tracking Accuracy Details" trigger="click"> <a>How does this work?</a></Popover>
                        </Form.Item>

                       
                        <Form.Item label='Group' name='group' rules={[{required: true, message: 'Please select a group'}]}>
                            <GroupSelect labelInValue />
                        </Form.Item>
                        { currentUser.role === 'admin' ?
                          <Form.Item label='Team' name='team'>
                              <TeamSelect labelInValue />
                          </Form.Item>
                          :
                          null
                        }

                        <Form.Item label="Disabled" name="disabled" valuePropName="checked" > 
                          <Checkbox  />
                        </Form.Item>
                        
                        
                       
                    </Col>
                   
                </Row>
               
            </Form>
        </Content>
            
        </div>
    )
}

export default UserForm



import React, {useState, useEffect} from 'react'
import {firestore} from '../firebase'
import {Select} from 'antd'
import { useRecoilState } from 'recoil'
import { userState } from '../atoms'

const GroupSelect = (props) => {
    
    const [groupSelectOptions, setGroupSelectOptions] = useState([]);
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    //const {placeholder, selectChanged} = props;

    useEffect( () => {
        async function fetchItems() {
            let query = firestore.collection('groups').orderBy('name');
            if (currentUser.role !== 'admin') {
                query = query.where('team_id','==',currentUser.team_id);
            }
            const groupRefs = await query.get();

            
            setGroupSelectOptions(groupRefs.docs.map(group => {
                                    return (<Select.Option key={group.id} value={group.id}>{group.data().name}</Select.Option>)
                                        }
                                ));
        }

        fetchItems();

       
    }, [])
    
    return (
        <Select {...props}>
            {groupSelectOptions}
        </Select>
    )
}

export default GroupSelect;

import React from "react";
import { Select } from "antd";
import { environment } from "../firebase";

const { Option } = Select;

function CheckinFrequencySelect(props) {
  let options = [
    <Option value="6">Every 6 minutes (for testing)</Option>,
    <Option value="15">Every 15 minutes</Option>,
    <Option value="30">Every half hour</Option>,
    <Option value="60">Every hour</Option>,
  ];

  if (environment === "dev") {
    options = [
      <Option value="5">Every 5 minutes</Option>,
      <Option value="6">Every 6 minutes (for testing)</Option>,
      <Option value="15">Every 15 minutes</Option>,
      <Option value="30">Every half hour</Option>,
      <Option value="60">Every hour</Option>,
      <Option value="90">Every hour and a half</Option>,
      <Option value="120">Every two hours</Option>,
    ];
  }

  return (
    <Select placeholder="Repeat Every" {...props}>
      {options}
    </Select>
  );
}

export default CheckinFrequencySelect;
